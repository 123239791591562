<template>
    <div>
        <div class="row page-titles">
            <div class="col-md-6 col-8 align-self-center">
                <h3 class="text-themecolor m-b-0 m-t-0">{{trans('task.task_detail')}}</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/home">{{trans('general.home')}}</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/abstracts">Abstracts</router-link></li>
                    <li class="breadcrumb-item active"> Abstract {{getTaskNumber(task)}}</li>
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="row m-b-5">
                    <div class="col-12 col-md-8 p-0">
                        <div class="btn btn-group">
                            <router-link v-if="task.sign_off_status === 'pending' || task.sign_off_status === 'payment'"  :to="`/abstracts/${task.uuid}/edit`" class="btn btn-warning btn-sm"><i class="fas fa-pencil-alt"></i> {{trans('general.edit')}} Abstract</router-link>
                            <router-link v-if="task.sign_off_status === 'shouldbecorrected' &&  hasPermission('edit-task')"  :to="`/abstracts/${task.uuid}/edit`" class="btn btn-warning btn-sm"><i class="fas fa-pencil-alt"></i> {{trans('general.edit')}} Abstract</router-link>
                        </div>
                    </div>
                    <div class="col-12 col-md-4 p-0">
                        <div class="btn btn-group pull-right">
                          <button @click="createTask" v-if="hasPermission('create-task')" class="btn btn-success btn-sm"><i class="fas fa-plus"></i> {{trans('task.add_new_task')}}</button>
                          <button class="btn btn-danger btn-sm" v-if="hasPermission('delete-task')" :key="task.id" v-confirm="{ok: confirmDelete(task)}"><i class="fas fa-trash"></i> {{trans('general.delete')}}</button>
                          <button class="btn btn-info btn-sm pull-right"   v-if="hasPermission('filter-abstracts')" data-toggle="modal" data-target=".task-config"><i class="fas fa-cog"></i></button>
                          <button class="btn btn-success btn-sm pull-right" v-if="hasPermission('filter-abstracts')"> Updated at {{task.updated_at | momentDateTime}}</button>
                        </div>
                    </div>
                </div>
                <task-config :uuid="uuid" :task="task" @completed="getTask"></task-config>
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 col-xs-12">
                            <p class="text-muted">Abstract Topic</p>
                            <strong v-html="task.title"></strong><br>
                          </div>
                            <div class="col-md-3 col-xs-6 b-r">
                                <strong>Abstract Number</strong>
                                <br>
                                <p class="text-muted">{{getTaskNumber(task)}}</p>
                                <br>
                                <div v-if="hasPermission('list-announcement')" class="user-profile pull-right"><div class="profile-img"> <img :src="getAvatar(task.user_added)" alt="user" /> </div></div>
                                <strong v-if="hasPermission('list-announcement')">Primary author</strong>
                                <br>
                                <p class="text-muted" v-if="hasPermission('list-announcement')">{{ task.user_added.profile.first_name+' '+task.user_added.profile.last_name}} <span class="badge badge-info">{{task.occupation}}</span> <button v-if="hasPermission('filter-abstracts') && abstract_count > 1" class="btn btn-danger btn-sm">{{abstract_count}}</button></p>
                            </div>
                            <div class="col-md-3 col-xs-6 b-r"> <strong>Session</strong>
                                <br>
                                <p class="text-muted">{{task.task_category.name}}</p>
                                <br>
                                <strong v-if="hasPermission('filter-abstracts')">Bioethics</strong>
                                <br>
                                <p class="text-muted" v-if="hasPermission('filter-abstracts')">
                                  <span v-if="task.bioethics == 'yes'" class="text-success m-r-20"><i class="fas fa-check-circle"></i> {{task.bioethics_comment}}</span>
                                  <span v-if="task.bioethics == 'no'" class="text-danger"><i class="fas fa-times-circle m-r-20"></i> {{task.bioethics_comment}} </span>
                                </p>
                            </div>
                            <div class="col-md-3 col-xs-6 b-r">
                                <strong>Submission Date</strong>
                                <br>
                                <p class="text-muted">{{task.created_at | momentDateTime}}</p>
                                <br>
                                <div v-if="hasPermission('list-announcement')">
                                <div>
                                  <strong>Signed Researchers' Approval</strong>
                                  <br>
                                    <ul style="list-style: none;padding: 0;" class="m-t-10">
                                        <li v-for="attachment in attachments">
                                            <a :href="`/abstracts/${task.uuid}/attachment/${attachment.uuid}/download?token=${authToken}`"><i class="fas fa-paperclip"></i> {{attachment.user_filename}}</a>
                                        </li>
                                    </ul>
                                  <li v-if="!attachments.length" style="color:red;">!!!</li>

                                </div>
                              </div>
                                <br>
                                <strong v-if="hasPermission('list-announcement')">Abstracts PDF</strong>
                                <br>
                                <p class="text-muted" v-if="hasPermission('list-announcement')"><a :href="`/abstracts/${task.uuid}/pdf/download?token=${authToken}`"><i class="fas fa-paperclip"></i> {{getTaskNumber(task)}}.pdf</a></p>
                            </div>
                            <div class="col-md-3 col-xs-6">
                              <div v-if="hasPermission('list-announcement')">
                                <div class="ribbon-wrapper card" style="margin-right: -15px;padding-top: 10px;" v-for="status in getTaskStatus(task)">
                                    <div :class="['ribbon','ribbon-'+status.color,'ribbon-right']">{{status.label}}</div>
                                </div>
                                <div class="ribbon-wrapper card" v-if="task.is_recurring" style="margin-right: -15px;padding-top: 10px;">
                                    <div :class="['ribbon','ribbon-danger','ribbon-right']" @click="toggleRecurringPanel" style="cursor:pointer;"><i class="fas fa-repeat"></i> {{trans('task.recurring')}}</div>
                                </div>
                                <div class="ribbon-wrapper card" style="margin-right: -15px;padding-top: 10px;" v-if="task.is_archived">
                                    <template v-if="task.user_id == getAuthUser('id') && task.sign_off_status === 'approved'">
                                        <div :class="['ribbon','ribbon-warning','ribbon-right']" :key="`archive_${task.id}`" v-confirm="{ok: confirmToggleArchive(task)}" style="cursor:pointer;" v-tooltip="trans('task.remove_from_archive')">{{trans('task.archived')}}</div>
                                    </template>
                                    <template v-else>
                                        <div :class="['ribbon','ribbon-warning','ribbon-right']">{{trans('task.archived')}}</div>
                                    </template>
                                </div>
                              </div>
                                <div v-if="task.user_id == getAuthUser('id')">
                                <div v-if="task.sign_off_status === 'payment'" style="text-align: center;">
                                <span> PAYMENT AMOUNT</span>
                                <br>
                                <span> <h2> {{task.price }} EUR</h2></span>
                                <span>Choose a Payment Method:</span><br>
                                <img src="https://docs.volt.io/wp-content/uploads/2023/06/Screenshot-2023-06-06-at-18.01.11-1024x571.png" data-toggle="modal" data-target="#paysera" style="padding: 10px;cursor: pointer;width: 180px;" alt="Montonio sąskaita"/>
                                <img src="/uploads/images/bank-transfer.png" style="cursor: pointer;padding: 10px;width: 180px;" data-toggle="modal" data-target="#bank_transfer" alt="Bank transfer"/>
                              <!--  <img src="https://bank.paysera.com/assets/image/payment_types/card.png" data-toggle="modal" data-target="#credit_card" style="padding: 10px;" alt="Mokėjimo kortelės"/> -->

                                <div class="modal fade" id="credit_card" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">PAYMENT BY CREDIT CARD</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <strong>Abstract Number</strong>
                                        <p class="text-muted">{{getTaskNumber(task)}}</p>
                                        <strong>Payment Amount</strong>
                                        <p class="text-muted">{{task.price }} EUR</p>
                                        <strong>Payment Method</strong><br>
                                        <img src="https://docs.volt.io/wp-content/uploads/2023/06/Screenshot-2023-06-06-at-18.01.11-1024x571.png"  style="margin-bottom: 25px;width: 50%;" alt="Mokėjimo kortelės"/>
                                    <p><b>  PRESS "PAY {{task.price }} EUR" TO CONTINUE </b></p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <a :href="`/payment/credit_card/${task.uuid}`" class="btn btn-primary">PAY {{task.price }} EUR</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="modal fade" id="paysera" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">PAYMENT BY MONTONIO (E-BANKING)</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <strong>Abstract Number</strong>
                                        <p class="text-muted">{{getTaskNumber(task)}}</p>
                                        <strong>Payment Amount</strong>
                                        <p class="text-muted">{{task.price }} EUR</p>
                                        <strong>Payment Method</strong><br>
                                        <img src="https://docs.volt.io/wp-content/uploads/2023/06/Screenshot-2023-06-06-at-18.01.11-1024x571.png"  style="margin-bottom: 25px;width: 50%;" alt="Mokėjimo kortelės"/>
                                    <p><b>  PRESS "PAY {{task.price }} EUR" TO CONTINUE </b></p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        <a :href="`/payment/paysera/${task.uuid}`" class="btn btn-primary">PAY {{task.price }} EUR</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="modal fade" id="bank_transfer" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                      <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">PAYMENT BY BANK TRANSFER (ONLY FOR INTERNATIONAL PARTICIPANTS)</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <strong>Abstract Number</strong>
                                        <p class="text-muted">{{getTaskNumber(task)}}</p>
                                        <strong>Payment Amount</strong>
                                        <p class="text-muted">{{task.price }} EUR</p>
                                        <strong>Payment Method</strong><br>
                                        <img src="/uploads/images/bank-transfer.png"  style="margin-bottom: 25px;" alt="Mokėjimo kortelės"/>
                                        <p>Please make payment to the following bank account: </p>
                                        <table border="1">
                                          <tbody>
                                            <tr>
                                              <th>Recipient</th>
                                              <td>LSMU studentų mokslinė draugija</td>
                                            </tr>
                                            <tr>
                                              <th>Recipient account</th>
                                              <td><span>LT217300010133733966</span></td>
                                            </tr>
                                            <tr>
                                              <th>Bank name</th>
                                              <td><span>„Swedbank", AB</span></td>
                                            </tr>
                                            <tr>
                                              <th>SWIFT</th>
                                              <td><span>HABALT22</span></td>
                                            </tr>
                                            <tr>
                                              <th>Bank address</th>
                                              <td><span>Konstitucijos 20A, Vilnius</span></td>
                                            </tr>
                                            <tr>
                                              <th><span>&nbsp;Purpose <span style="color: rgb(255,0,0)">*</span></span></th>
                                              <td><strong><span> <b>{{getTaskNumber(task)}}</b></span></strong></td>
                                            </tr>
                                            <tr>
                                              <th><span>Amount</span></th>
                                              <td><strong><span>{{task.price }} EUR</span></strong></td>
                                            </tr>
                                          </tbody>
                                        </table>
                                        <div><strong><span style="color: rgb(255,0,0)">* Very important</span></strong>&nbsp;</div>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="hasPermission('filter-abstracts')" class="col-12 col-lg-4 col-md-4">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Abstract Reviewers</h4>
                        <h6 class="card-subtitle" v-if="task.user.length">{{trans('general.total_result_found',{'count' : task.user.length})}}</h6>
                        <h6 class="card-subtitle" v-else>{{trans('general.no_result_found')}}</h6>
                        <br />
                        <div class="d-flex flex-row m-b-20" v-if="task.user" v-for="user in task.user">
                            <div class=""><img :src="getAvatar(user)" alt="user" class="img-circle" width="70"></div>
                            <div class="p-l-20">
                              <div class="row">
                                <div class="col-sm-10"><h4 class="font-medium"><b>{{user.pivot.review_no}}.</b> {{user.profile.first_name+' '+user.profile.last_name}} ({{user.id}})</h4></div>
                                <div class="col-sm-1"><div style="text-align: right;margin-top: -3px;color: red;margin-right: 0px;" v-confirm="{ok: confirmRemoveReviewer(user, task)}"  v-tooltip="'Remove reviewer'" ><i class="fas fa-trash-alt"></i></div></div>
                              </div>
                              <h6 v-if="user.profile.designation"> {{user.profile.designation.name+' '+trans('general.in')+' '+user.profile.designation.department.name}}</h6>
                                <h6 v-if="user.pivot.review === 1">First review</h6>
                                <h6 v-if="user.pivot.review === 2">Second review</h6>
                                <span class="time"><small>{{trans('task.assigned_at')}} {{user.pivot.created_at | momentDateTime}}</small></span> <br />

                            </div>
                        </div>
                        <div class="card-subtitle" v-if="!task.user">{{trans('task.no_assigned_user')}}</div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-12 col-md-12" >
                <div class="card">
                    <ul class="nav nav-tabs profile-tab" role="tablist">
                        <li class="nav-item" v-if="hasPermission('list-announcement')"> <a class="nav-link active" data-toggle="tab" href="#detail" role="tab" @click="showHideTabs('showDetailTab')">Abstract</a> </li>
                        <li class="nav-item" v-if="hasPermission('do-review')"> <a class="nav-link" data-toggle="tab" href="#question" role="tab" @click="showHideTabs('showQuestionTab')">Review abstract</a> </li>
                        <li class="nav-item" v-if="hasPermission('see-all-review')"> <a class="nav-link" data-toggle="tab" href="#rating" role="tab" @click="showHideTabs('showTaskRatingTab')">Reviews Editor</a> </li>
                        <li class="nav-item" v-if="task.show_reviews == 1 && task.user_id == getAuthUser('id')"> <a class="nav-link" data-toggle="tab" href="#rating" role="tab" @click="showHideTabs('showTaskRatingTab')">Reviews</a> </li>
                        <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#note" role="tab" @click="showHideTabs('showTaskNoteTab')">Notes</a> </li>
                        <li class="nav-item" v-if="hasAdminRole()"> <a class="nav-link" data-toggle="tab" href="#sign-off" role="tab" @click="showHideTabs('showTaskSignOffTab')">Activity list</a> </li>
                    </ul>
                    <div class="tab-content">
                        <div :class="{ 'tab-pane active': hasPermission('list-announcement') }" class="tab-pane active" v-if="hasPermission('list-announcement')" id="detail" role="tabpanel">
                            <div class="card-body" v-if="tabs.showDetailTab">
                              <div>
                              <h5><b>Authors</b></h5>
                              <div style="line-height: 20px; margin-bottom: 10px;" v-for="author in task.authors">
                                <b><i>{{author.scientific_degree}}</i> {{author.firstname}} {{author.lastname}}</b> {{author.email}}<br> <i>{{author.affiliation}}</i>
                              </div>
                            </div>
                              <div v-if="task.format === 'Oral/Poster'">
                              <div style="margin-bottom: 25px;"></div>
                              <h5><b>Abstract Topic</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.title"></div>
                              <div class="clearfix"></div>
                              <h5><b>Introduction</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.introduction"></div>
                              <div class="clearfix"></div>
                              <h5><b>Aim</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.aim"></div>
                              <div class="clearfix"></div>
                              <h5><b>Methods</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.methods"></div>
                              <div class="clearfix"></div>
                              <h5><b>Results</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.results"></div>
                              <div class="clearfix"></div>
                              <h5><b>Conclusions</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.conclusions"></div>
                              <div class="clearfix"></div>
                              <h5><b>References</b></h5>
                              <div style="margin-bottom: 25px;" v-html="task.references"></div>
                              </div>
                              <div v-if="task.format === 'Case report'">
                                <div style="margin-bottom: 25px;"></div>
                                <h5><b>Title</b></h5>
                                <div style="margin-bottom: 25px;" v-html="task.title"></div>
                                <div class="clearfix"></div>
                                <h5><b>Introduction</b></h5>
                                <div style="margin-bottom: 25px;" v-html="task.introduction"></div>
                                <div class="clearfix"></div>
                                <h5><b>Case presentation</b></h5>
                                <div style="margin-bottom: 25px;" v-html="task.aim"></div>
                                <div class="clearfix"></div>
                                <h5><b>Discussion</b></h5>
                                <div style="margin-bottom: 25px;" v-html="task.methods"></div>
                                <div class="clearfix"></div>
                                <h5><b>Conclusions</b></h5>
                                <div style="margin-bottom: 25px;" v-html="task.conclusions"></div>
                                <div class="clearfix"></div>
                                <h5><b>References</b></h5>
                                <div style="margin-bottom: 25px;" v-html="task.references"></div>
                              </div>
                            </div>
                        </div>
                        <div class="tab-pane active" id="question" role="tabpanel" v-if="task.question_set">
                            <div class="card-body" v-if="tabs.showQuestionTab">
                                <h4 class="card-title">{{trans('task.answer_to_questions')}}</h4>
                                <template v-if="!is_locked">
                                  <div class="row">
                                    <div class="col-12 col-sm-6">
                                      <div v-if="task.format === 'Oral/Poster'">
                                      <div style="margin-bottom: 25px;"></div>
                                      <h5><b>Abstract Topic</b></h5>
                                      <div style="margin-bottom: 25px;" v-html="task.title"></div>
                                      <div class="clearfix"></div>
                                      <h5><b>Introduction</b></h5>
                                      <div style="margin-bottom: 25px;">{{ToText(task.introduction)}}</div>
                                      <div class="clearfix"></div>
                                      <h5><b>Aim</b></h5>
                                      <div style="margin-bottom: 25px;">{{ToText(task.aim)}}</div>
                                      <div class="clearfix"></div>
                                      <h5><b>Methods</b></h5>
                                      <div style="margin-bottom: 25px;">{{ToText(task.methods)}}</div>
                                      <div class="clearfix"></div>
                                      <h5><b>Results</b></h5>
                                      <div style="margin-bottom: 25px;">{{ToText(task.results)}}</div>
                                      <div class="clearfix"></div>
                                      <h5><b>Conclusions</b></h5>
                                      <div style="margin-bottom: 25px;" >{{ToText(task.conclusions)}}</div>
                                      <div class="clearfix"></div>
                                      <h5><b>References</b></h5>
                                      <div style="margin-bottom: 25px;">{{ToText(task.references)}}</div>
                                      </div>
                                      <div v-if="task.format === 'Case report'">
                                        <div style="margin-bottom: 25px;"></div>
                                        <h5><b>Abstract Topic</b></h5>
                                        <div style="margin-bottom: 25px;" v-html="task.title"></div>
                                        <div class="clearfix"></div>
                                        <h5><b>Introduction</b></h5>
                                        <div style="margin-bottom: 25px;">{{ToText(task.introduction)}}</div>
                                        <div class="clearfix"></div>
                                        <h5><b>Case presentation</b></h5>
                                        <div style="margin-bottom: 25px;">{{ToText(task.aim)}}</div>
                                        <div class="clearfix"></div>
                                        <h5><b>Discussion</b></h5>
                                        <div style="margin-bottom: 25px;">{{ToText(task.methods)}}</div>
                                        <div class="clearfix"></div>
                                        <h5><b>Conclusions</b></h5>
                                        <div style="margin-bottom: 25px;" >{{ToText(task.conclusions)}}</div>
                                        <div class="clearfix"></div>
                                        <h5><b>References</b></h5>
                                        <div style="margin-bottom: 25px;">{{ToText(task.references)}}</div>
                                      </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                      <div v-if="task.review_no == 2">
                                        <h3>Your reviews:</h3>
                                      </br>
                                        <div class="col-12 col-sm-12" v-for="answer in reviews">
                                          <div class="row" v-if="getAuthUser('id') == answer.user_id">
                                            <div class="col-3 col-sm-3">{{answer.question}}</div>
                                            <div class="col-3 col-sm-3" v-if="answer.question === 'Abstract is accepted/ declined / should be corrected'">
                                              <span v-if="answer.answer == '1'" class="text-success m-r-20"><i class="fas fa-check-circle"></i> Accepted</span>
                                              <span v-if="answer.answer == '2'" class="text-warning m-r-20"><i class="fas fa-question-circle"></i> Should be corrected</span>
                                              <span v-if="answer.answer == '0'" class="text-danger"><i class="fas fa-times-circle m-r-20"></i> Declined </span>
                                            </div>
                                            <div class="col-3 col-sm-3" v-else-if="answer.question === 'I recommend this abstract for oral presentation / poster presentation'">
                                              <span v-if="answer.answer == '1'"><i class="far fa-file-powerpoint"></i> Presentation </span>
                                              <span v-if="answer.answer == '2'"><i class="fas fa-question-circle"></i> Unclear</span>
                                              <span v-if="answer.answer == '0'"><i class="far fa-file-word"></i> Poster </span>
                                            </div>
                                            <div class="col-3 col-sm-3" v-else>
                                              <span v-if="answer.answer == '1'" class="text-success m-r-20"><i class="fas fa-check-circle"></i> Yes</span>
                                              <span v-if="answer.answer == '2'" class="text-warning m-r-20"><i class="fas fa-question-circle"></i> Should be corrected</span>
                                              <span v-if="answer.answer == '0'" class="text-danger"><i class="fas fa-times-circle m-r-20"></i> No </span>
                                            </div>
                                            <div class="col-5 col-sm-5" v-if="answer.description"><i class="fas fa-comments" style="margin-right: 10px;"></i> <i>{{answer.description}}</i></div>
                                            <div class="col-5 col-sm-5" v-else><i class="fas fa-comments" style="margin-right: 10px;"></i> -</div>
                                            </div>

                                        </div>
                                        <hr>
                                      </br>

                                      </div>
                                    <form @submit.prevent="submit" @keydown="questionForm.errors.clear($event.target.name)">
                                        <template v-for="answer in questionForm.answers">
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <label for=""><b>{{answer.question}}</b></label>
                                                        <i><div v-html="answer.comment"></div></i>
                                                        <div class="radio radio-info text-center" style="margin-top: 20px;">
                                                            <input type="radio" value="yes" :id="`answer_${answer.id}_1`" v-model="answer.answer" :checked="answer.answer === '1'" :name="`answer_${answer.id}`" @click="questionForm.errors.clear(`answer_${answer.id}`)">
                                                            <label :for="`answer_${answer.id}_1`" class="m-r-20"> {{trans('list.yes')}} </label>
                                                            <input type="radio" value="no" :id="`answer_${answer.id}_0`" v-model="answer.answer" :checked="answer.answer === '0'" :name="`answer_${answer.id}`" @click="questionForm.errors.clear(`answer_${answer.id}`)">
                                                            <label :for="`answer_${answer.id}_0`" style="margin-right: 15px;"> {{trans('list.no')}} </label>
                                                            <input type="radio" v-if="task.review_no == 1" value="shouldbecorrected" :id="`answer_${answer.id}_2`" v-model="answer.answer" :checked="answer.answer === '2'" :name="`answer_${answer.id}`" @click="questionForm.errors.clear(`answer_${answer.id}`)">
                                                            <label v-if="task.review_no == 1" :for="`answer_${answer.id}_2`"> Should be corrected  </label>
                                                        </div>
                                                        <show-error :form-name="questionForm" :prop-name="`answer_${answer.id}`"></show-error>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12">
                                                    <div class="form-group">
                                                        <autosize-textarea rows="2" class="form-control" v-model="answer.description" placeholder="Comments" name="description"></autosize-textarea>
                                                    </div>
                                                </div>
                                        </template>
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-info pull-right">{{trans('general.save')}}</button>
                                        </div>
                                    </form>
                                  </div>
                                      </div>
                                </template>
                                <template v-else>
                                  <div class="alert alert-success" role="alert">
                                    Your review was successfully saved!
                                  </div>


                                </template>
                            </div>
                        </div>
                        <div class="tab-pane" id="sign-off" role="tabpanel">
                            <div class="card-body" v-if="tabs.showTaskSignOffTab">
                            </div>
                        </div>
                        <div class="tab-pane" id="rating" role="tabpanel">
                            <div class="row" style="padding: 15px;">
                            <div class="col-12 col-sm-12" v-for="answer in reviews">
                              <div class="row" v-if="hasPermission('see-all-review')">
                                <div class="col-2 col-sm-2"><b>Reviewer No {{answer.review_no}} ({{answer.user_id}})</b>

                                </div>

                                <div class="col-2 col-sm-2" v-if="answer.question === 'Abstract is accepted/ declined / should be corrected'">
                                  <span v-if="answer.answer == '1'" class="text-success m-r-20"><i class="fas fa-check-circle"></i> Accepted</span>
                                  <span v-if="answer.answer == '2'" class="text-warning m-r-20"><i class="fas fa-question-circle"></i> Should be corrected</span>
                                  <span v-if="answer.answer == '0'" class="text-danger"><i class="fas fa-times-circle m-r-20"></i> Declined </span>
                                </div>
                                <div class="col-2 col-sm-2" v-else-if="answer.question === 'I recommend this abstract for oral presentation / poster presentation'">
                                  <span v-if="answer.answer == '1'"><i class="far fa-file-powerpoint"></i> Presentation </span>
                                  <span v-if="answer.answer == '2'"><i class="fas fa-question-circle"></i> Unclear</span>
                                  <span v-if="answer.answer == '0'"><i class="far fa-file-word"></i> Poster </span>
                                </div>
                                <div class="col-2 col-sm-2" v-else>
                                  <span v-if="answer.answer == '1'" class="text-success m-r-20"><i class="fas fa-check-circle"></i> Yes</span>
                                  <span v-if="answer.answer == '2'" class="text-warning m-r-20"><i class="fas fa-question-circle"></i> Should be corrected</span>
                                  <span v-if="answer.answer == '0'" class="text-danger"><i class="fas fa-times-circle m-r-20"></i> No </span>
                                </div>
                                 <div class="col-3 col-sm-3">{{answer.question}}</div>
                                <div class="col-5 col-sm-5" v-if="answer.description"><i class="fas fa-comments" style="margin-right: 10px;"></i> <i>{{answer.description}}</i></div>
                                <div class="col-5 col-sm-5" v-else><i class="fas fa-comments" style="margin-right: 10px;"></i> -</div>
                                </div>
                                <div class="row" v-else>
                                  <div v-if="answer.question === 'Abstract is accepted/ declined / should be corrected'" class="col-2 col-sm-2"></div>
                                  <div v-else-if="answer.question === 'I recommend this abstract for oral presentation / poster presentation'" class="col-2 col-sm-2"></div>
                                  <div v-else class="col-2 col-sm-2"><b>Reviewer No {{answer.review_no}} ({{answer.user_id}})</b></div>
                                  <div v-if="answer.question === 'Abstract is accepted/ declined / should be corrected'" class="col-5 col-sm-3"></div>
                                  <div v-else-if="answer.question === 'I recommend this abstract for oral presentation / poster presentation'" class="col-5 col-sm-3"></div>
                                  <div v-else class="col-4 col-sm-3">{{answer.question}}</div>
                                  <div v-if="answer.question === 'Abstract is accepted/ declined / should be corrected'" class="col-5 col-sm-3"></div>
                                  <div v-else-if="answer.question === 'I recommend this abstract for oral presentation / poster presentation'" class="col-5 col-sm-3"></div>
                                  <div v-else class="col-6 col-sm-7"><i class="fas fa-comments" style="margin-right: 10px;"></i> <i>{{answer.description}}</i></div>

                                  </div>
                              </hr>
                            </div>
                            <div class="col-12 col-sm-12" v-if="reviews.length === 0">
                              <div class="alert alert-warning" role="alert">
                              No results found
                              </div>
                            </div>

                        </div>
                        </div>
                        <div class="tab-pane" id="sub-task" role="tabpanel">
                            <div class="card-body" v-if="tabs.showSubTaskTab">
                                <sub-task :uuid="uuid" @updateProgress="getTask"></sub-task>
                            </div>
                        </div>
                        <div class="tab-pane" id="comment" role="tabpanel">
                            <div class="card-body" v-if="tabs.showTaskCommentTab">
                                <task-comment :uuid="uuid"></task-comment>
                            </div>
                        </div>
                        <div class="tab-pane" id="note" role="tabpanel">
                            <div class="card-body" v-if="tabs.showTaskNoteTab">
                                <task-note :uuid="uuid"></task-note>
                            </div>
                        </div>
                        <div class="tab-pane" id="attachment" role="tabpanel">
                            <div class="card-body" v-if="tabs.showTaskAttachmentTab">
                                <task-attachment :uuid="uuid"></task-attachment>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import taskSignOff from './task-sign-off'
    import subTask from './sub-task'
    import taskComment from './task-comment'
    import taskNote from './task-note'
    import taskAttachment from './task-attachment'
    import taskConfig from './task-config'
    import taskRecurring from './task-recurring'
    import taskCopy from './task-copy'
    import taskRating from './task-rating'
    import rangeSlider from 'vue-range-slider'
    import autosizeTextarea from '@components/autosize-textarea'
    import htmlEditor from '@components/html-editor'

    export default {
        components:{subTask,taskComment,taskNote,taskAttachment,taskConfig,taskRecurring,taskCopy,rangeSlider,taskSignOff,taskRating, autosizeTextarea, htmlEditor},
        data() {
            return {
                uuid:this.$route.params.uuid,
                moment:moment,
                task: {
                    user_added:{
                        profile: {

                        }
                    },
                    task_category: {

                    },
                    task_priority: {

                    },
                    user:[]
                },
                questionForm: new Form({
                    question_set_id: '',
                    answers: []
                }),
                sendForm: new Form({

                    text: ''
                }),
                is_locked: 0,
                assigned_user: [],
                progress: 0,
                abstract_count: '',
                is_editable: false,
                attachments: [],
                starred_tasks: [],
                answers: [],
                reviews: [],
                question_set: {},
                showRecurringPanel: false,
                showCopyPanel: false,
                taskProgressForm: new Form({
                    progress: 0
                },false),
                tabs: {
                    showDetailTab: true,
                    showQuestionTab: false,
                    showTaskSignOffTab: false,
                    showTaskRatingTab: false,
                    showSubTaskTab: false,
                    showTaskCommentTab: false,
                    showTaskNoteTab: false,
                    showTaskAttachmentTab: false,
                }
            }
        },
        mounted(){
            this.getTask();
        },
        methods: {
            showHideTabs(activeTab){
                for(let tab in this.tabs)
                    if(tab !== activeTab)
                    this.tabs[tab] = false;
                this.tabs[activeTab] = true;
            },
            hasRole(role){
                return helper.hasRole(role);
            },
            hasAdminRole(){
                return helper.hasAdminRole();
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            sliderChange(value){
                this.taskProgressForm.progress = value;
                this.updateProgress();
            },
            getAvatar(user){
                return helper.getAvatar(user);
            },
            getTask(){
                axios.get('/api/task/'+this.uuid)
                    .then(response => response.data)
                    .then(response => {
                        this.task = response.task;
                        this.task.authors = JSON.parse(response.task.authors);
                        this.is_locked = response.is_locked;
                        this.abstract_count = response.abstract_count;
                        this.reviews = response.reviews;
                        this.assigned_user = response.user_id;
                        this.is_editable = response.is_editable;
                        this.attachments = response.attachments;
                        this.starred_tasks = response.starred_tasks;
                        this.taskProgressForm.progress = response.task.progress;
                        this.progress = helper.getTaskProgress(this.task);
                        this.question_set = response.question_set;

                        this.questionForm.question_set_id = this.task.question_set_id;

                        if(this.question_set) {
                            this.questionForm.answers = [];
                            this.question_set.questions.forEach(question => {
                                let answer = this.task.answers.find( o => o.question_id === question.id);
                                this.questionForm.answers.push({
                                        'question': question.question,
                                        'id': question.id,
                                        'comment': question.comment,
                                        'answer': '',
                                        'description':  ''
                                    });
                            });
                        }
                    })
                    .catch(error => {
                        this.$router.push('/abtracts');
                    });
                },
                getAnswers(){
                    axios.get('/abstracts/reviews/'+this.uuid)
                        .then(response => response.data)
                        .then(response => {
                            this.answers = response.reviews;
                        })
                        .catch(error => {
                            this.$router.push('/abtracts');
                        });
                    },
            confirmDelete(task){
                return dialog => this.deleteTask(task);
            },

            confirmRemoveReviewer(user, task){
                return dialog => this.deleteReviewer(user, task);
            },

            deleteTask(task){
                axios.delete('/api/task/'+task.uuid)
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.$router.push('/abstracts');
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            deleteReviewer(user, task){
                axios.delete('/api/task/'+task.uuid+'/reviewer/'+user.id)
                    .then(response => response.data)
                    .then(response => {
                        this.getTask();
                        toastr.success(response.message);
                        this.$emit('completed');
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            createTask(){
                this.$router.push('/abstracts/create');
            },
            ToText(HTML){
              var input = HTML;
              return input.replace(/<(style|script|iframe)[^>]*?>[\s\S]+?<\/\1\s*>/gi,'').replace(/<[^>]+?>/g,'').replace(/\s+/g,' ').replace(/ /g,' ').replace(/>/g,' ').replace(/&nbsp;/g, ' ').replace(/&lt;/g,'<').replace(/&gt;/g,'>').replace(/&amp;/g,'&');
            },
            toggleStar(task){
                axios.post('/api/task/'+task.uuid+'/star')
                    .then(response => response.data)
                    .then(response => {
                        if(this.isTaskStarred)
                            this.starred_tasks.splice(this.starred_tasks.indexOf(this.getAuthUser('id')), 1);
                        else
                            this.starred_tasks.push(this.getAuthUser('id'));
                    })
                    .catch(error => {

                    });
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            toggleRecurringPanel(){
                this.showRecurringPanel = !this.showRecurringPanel;
                this.showCopyPanel = false;
            },
            toggleCopyPanel(){
                this.showCopyPanel = !this.showCopyPanel;
                this.showRecurringPanel = false;
            },
            updateProgress(){
                this.taskProgressForm.post('/api/task/'+this.uuid+'/progress')
                    .then(response => {
                        this.getTask();
                        toastr.success(response.message);
                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            confirmToggleArchive(task){
                return dialog => this.toggleArchive(task);
            },
            toggleArchive(task){
                axios.post('/api/task/'+task.uuid+'/archive')
                    .then(response => response.data)
                    .then(response => {
                        toastr.success(response.message);
                        this.getTask();
                    }).catch(error => {
                        helper.showDataErrorMsg(error);
                    });
            },
            getTaskStatus(task){
                return helper.getTaskStatus(task);
            },
            getTaskUserRating(user,task){
                return helper.getTaskUserRating(user,task);
            },
            getTaskNumber(task){
                return helper.getTaskNumber(task);
            },
            submit(){
                this.questionForm.post('/api/task/'+this.task.uuid+'/answer')
                    .then(response => {
                        toastr.success(response.message);
                        this.questionForm.answers = [];
                        this.is_locked = 1;
                        this.getTask();
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            sendreviews(){
                this.sendForm.post('/task/'+this.task.uuid+'/sendauthor')
                    .then(response => {
                        toastr.success(response.message);
                    }).catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
        },
        computed: {
            authToken(){
                return localStorage.getItem('auth_token');
            },
            isTaskStarred(){
                if(this.starred_tasks.indexOf(this.getAuthUser('id')) != -1)
                    return 1;

                return 0;
            },
            isRatingAllowed(){
                return this.task.sign_off_status === 'approved' ? 1 : 0;
            }
        },
        filters: {
          moment(date) {
            return helper.formatDate(date);
          },
          momentDateTime(datetime) {
            return helper.formatDateTime(datetime);
          },
        },
        watch: {
            '$route.params.uuid'(newId, oldId) {
                this.uuid = newId;
                this.getTask()
                this.getAnswers()
            },
        }
    }
</script>
<style>
    .slider{
        width: 100%;
    }
    .range-slider-fill{
        background-color: green;
    }
</style>
