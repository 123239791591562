var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("general.home")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(_vm._s(_vm.trans("general.home")))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.hasPermission("show-counts")
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 col-md-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Total Abstracts")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c("h2", { staticClass: "font-light m-b-0" }, [
                    _c("i", {
                      staticClass: "fas fa-tasks fa-lg pull-right text-info"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pull-left" }, [
                      _vm._v(_vm._s(_vm.task_stats.total))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 col-md-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Pending Abstratcs")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c("h2", { staticClass: "font-light m-b-0" }, [
                    _c("i", {
                      staticClass: "fas fa-spinner fa-lg pull-right text-danger"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pull-left" }, [
                      _vm._v(_vm._s(_vm.task_stats.pending))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 col-md-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Waiting for Payment")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c("h2", { staticClass: "font-light m-b-0" }, [
                    _c("i", {
                      staticClass: "fas fa-spinner fa-lg pull-right text-danger"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pull-left" }, [
                      _vm._v(_vm._s(_vm.task_stats.payment))
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 col-md-3" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Approved Abstracts")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c("h2", { staticClass: "font-light m-b-0" }, [
                    _c("i", {
                      staticClass:
                        "fas fa-battery-full fa-lg pull-right text-success"
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "pull-left" }, [
                      _vm._v(_vm._s(_vm.task_stats.completed))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.hasPermission("do-review")
      ? _c("div", { staticClass: "row" }, [_vm._m(0)])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.getConfig("announcement")
        ? _c("div", { staticClass: "col-12 col-md-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("div", { staticClass: "message-scroll" }, [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Announcements")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "comment-widgets" },
                    _vm._l(_vm.announcements, function(announcement) {
                      return _c(
                        "div",
                        { staticClass: "d-flex flex-row comment-row" },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(announcement.description)
                            }
                          })
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  !_vm.announcements.length
                    ? _c("h6", { staticClass: "card-subtitle" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.trans("general.no_result_found")) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "message-scroll" }, [
            _c("h4", { staticClass: "card-title" }, [
              _vm._v(
                "\n              CALL FOR ABSTRACTS | OPEN UNTIL 14th of January 2024, 23:59 EET\n            "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "comment-widgets" }, [
              _c("h1", { staticStyle: { "text-align": "center" } }, [
                _c("strong", [
                  _vm._v(
                    "Welcome to the Abstract Submission, Peer Review System for\n                  IHSC 2024"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _c("span", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v(
                    "Please make sure you have read the\n                  "
                  ),
                  _c(
                    "a",
                    {
                      attrs: {
                        target: "_blank",
                        href: "https://ihsc.eu/index.php/participation/",
                        rel: "noopener"
                      }
                    },
                    [_vm._v("guidelines and responsibilities")]
                  ),
                  _vm._v(
                    "\n                  associated with submitting an abstract.  "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { "text-align": "center" } }, [
                _c("span", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v("As the submitter,\n                  "),
                  _c(
                    "span",
                    { staticStyle: { "text-decoration": "underline" } },
                    [_vm._v("you will be designated the Primary author")]
                  ),
                  _vm._v(
                    "\n                  and will receive all communications (confirmations,\n                  notifications, reviews, etc).  It is your\n                  responsibility to forward any relevant communications to\n                  your co-authors and fulfill the tasks by the appointed\n                  deadlines."
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }