var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("aside", { staticClass: "left-sidebar" }, [
    _c("div", { staticClass: "scroll-sidebar" }, [
      _c("nav", { staticClass: "sidebar-nav m-t-20" }, [
        _vm.getConfig("maintenance_mode")
          ? _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "badge badge-danger m-b-10" }, [
                _vm._v(_vm._s(_vm.trans("configuration.under_maintenance")))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.getConfig("mode")
          ? _c("div", { staticClass: "text-center" }, [
              _c("span", { staticClass: "badge badge-danger m-b-10" }, [
                _vm._v(_vm._s(_vm.trans("configuration.test_mode")))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("ul", { attrs: { id: "sidebarnav" } }, [
          _c(
            "li",
            [
              _c("router-link", { attrs: { to: "/home", exact: "" } }, [
                _c("i", { staticClass: "fas fa-home fa-fw" }),
                _vm._v(" "),
                _c("span", { staticClass: "hide-menu" }, [
                  _vm._v(_vm._s(_vm.trans("general.home")))
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasPermission("list-department") &&
          _vm.getConfig("show_department_menu")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/department", exact: "" } },
                    [
                      _c("i", { staticClass: "fas fa-university fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("department.department")))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("list-designation") &&
          _vm.getConfig("show_designation_menu")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/designation", exact: "" } },
                    [
                      _c("i", { staticClass: "fas fa-sitemap fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("designation.designation")))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("list-location") &&
          _vm.getConfig("show_location_menu")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/location", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-code-branch fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("location.location")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("list-user") && _vm.getConfig("show_user_menu")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/user", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-users fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("user.user")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("list-user") &&
          _vm.getConfig("show_announcement_menu")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/announcement", exact: "" } },
                    [
                      _c("i", { staticClass: "fas fa-bullhorn fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("announcement.announcement")))
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("list-task") && _vm.getConfig("show_task_menu")
            ? _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/abstracts", exact: "" } },
                    [
                      _c("i", { staticClass: "fas fa-tasks fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v("Abstracts")
                      ])
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("list-department") &&
          _vm.getConfig("show_department_menu")
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "has-arrow",
                    attrs: { href: "#", "aria-expanded": "false" }
                  },
                  [
                    _c("i", { staticClass: "fas fa-file fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("general.report")))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "collapse",
                    attrs: { "aria-expanded": "false" }
                  },
                  [
                    _c(
                      "li",
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/report/abstracts/summary" } },
                          [
                            _c("i", {
                              staticClass: "fas fa-angle-double-right"
                            }),
                            _vm._v(" " + _vm._s(_vm.trans("task.task_summary")))
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPermission("access-message") &&
          _vm.getConfig("show_message_menu")
            ? _c(
                "li",
                [
                  _c("router-link", { attrs: { to: "/message", exact: "" } }, [
                    _c("i", { staticClass: "fas fa-envelope-open fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("message.message")))
                    ])
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sidebar-footer" },
      [
        _vm.hasPermission("access-configuration")
          ? _c(
              "router-link",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.trans("configuration.configuration"),
                    expression: "trans('configuration.configuration')"
                  }
                ],
                staticClass: "link",
                attrs: { to: "/configuration" }
              },
              [_c("i", { staticClass: "fas fa-cogs" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "router-link",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("user.profile"),
                expression: "trans('user.profile')"
              }
            ],
            staticClass: "link",
            attrs: { to: "/profile" }
          },
          [_c("i", { staticClass: "fas fa-user" })]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.trans("auth.logout"),
                expression: "trans('auth.logout')"
              }
            ],
            staticClass: "link",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.logout($event)
              }
            }
          },
          [_c("i", { staticClass: "fas fa-power-off" })]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }