var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v(_vm._s(_vm.trans("task.task_detail")))
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/abstracts" } }, [
                _vm._v("Abstracts")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v(" Abstract " + _vm._s(_vm.getTaskNumber(_vm.task)))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("div", { staticClass: "row m-b-5" }, [
            _c("div", { staticClass: "col-12 col-md-8 p-0" }, [
              _c(
                "div",
                { staticClass: "btn btn-group" },
                [
                  _vm.task.sign_off_status === "pending" ||
                  _vm.task.sign_off_status === "payment"
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-warning btn-sm",
                          attrs: { to: "/abstracts/" + _vm.task.uuid + "/edit" }
                        },
                        [
                          _c("i", { staticClass: "fas fa-pencil-alt" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.trans("general.edit")) +
                              " Abstract"
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.task.sign_off_status === "shouldbecorrected" &&
                  _vm.hasPermission("edit-task")
                    ? _c(
                        "router-link",
                        {
                          staticClass: "btn btn-warning btn-sm",
                          attrs: { to: "/abstracts/" + _vm.task.uuid + "/edit" }
                        },
                        [
                          _c("i", { staticClass: "fas fa-pencil-alt" }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.trans("general.edit")) +
                              " Abstract"
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-md-4 p-0" }, [
              _c("div", { staticClass: "btn btn-group pull-right" }, [
                _vm.hasPermission("create-task")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm",
                        on: { click: _vm.createTask }
                      },
                      [
                        _c("i", { staticClass: "fas fa-plus" }),
                        _vm._v(" " + _vm._s(_vm.trans("task.add_new_task")))
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermission("delete-task")
                  ? _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "confirm",
                            rawName: "v-confirm",
                            value: { ok: _vm.confirmDelete(_vm.task) },
                            expression: "{ok: confirmDelete(task)}"
                          }
                        ],
                        key: _vm.task.id,
                        staticClass: "btn btn-danger btn-sm"
                      },
                      [
                        _c("i", { staticClass: "fas fa-trash" }),
                        _vm._v(" " + _vm._s(_vm.trans("general.delete")))
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermission("filter-abstracts")
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-info btn-sm pull-right",
                        attrs: {
                          "data-toggle": "modal",
                          "data-target": ".task-config"
                        }
                      },
                      [_c("i", { staticClass: "fas fa-cog" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasPermission("filter-abstracts")
                  ? _c(
                      "button",
                      { staticClass: "btn btn-success btn-sm pull-right" },
                      [
                        _vm._v(
                          " Updated at " +
                            _vm._s(
                              _vm._f("momentDateTime")(_vm.task.updated_at)
                            )
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          ]),
          _vm._v(" "),
          _c("task-config", {
            attrs: { uuid: _vm.uuid, task: _vm.task },
            on: { completed: _vm.getTask }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12 col-xs-12" }, [
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v("Abstract Topic")
                  ]),
                  _vm._v(" "),
                  _c("strong", {
                    domProps: { innerHTML: _vm._s(_vm.task.title) }
                  }),
                  _c("br")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                  _c("strong", [_vm._v("Abstract Number")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.getTaskNumber(_vm.task)))
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("list-announcement")
                    ? _c("div", { staticClass: "user-profile pull-right" }, [
                        _c("div", { staticClass: "profile-img" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.getAvatar(_vm.task.user_added),
                              alt: "user"
                            }
                          })
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasPermission("list-announcement")
                    ? _c("strong", [_vm._v("Primary author")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("list-announcement")
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(
                            _vm.task.user_added.profile.first_name +
                              " " +
                              _vm.task.user_added.profile.last_name
                          ) + " "
                        ),
                        _c("span", { staticClass: "badge badge-info" }, [
                          _vm._v(_vm._s(_vm.task.occupation))
                        ]),
                        _vm._v(" "),
                        _vm.hasPermission("filter-abstracts") &&
                        _vm.abstract_count > 1
                          ? _c(
                              "button",
                              { staticClass: "btn btn-danger btn-sm" },
                              [_vm._v(_vm._s(_vm.abstract_count))]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                  _c("strong", [_vm._v("Session")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(_vm._s(_vm.task.task_category.name))
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("filter-abstracts")
                    ? _c("strong", [_vm._v("Bioethics")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("filter-abstracts")
                    ? _c("p", { staticClass: "text-muted" }, [
                        _vm.task.bioethics == "yes"
                          ? _c("span", { staticClass: "text-success m-r-20" }, [
                              _c("i", { staticClass: "fas fa-check-circle" }),
                              _vm._v(" " + _vm._s(_vm.task.bioethics_comment))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.task.bioethics == "no"
                          ? _c("span", { staticClass: "text-danger" }, [
                              _c("i", {
                                staticClass: "fas fa-times-circle m-r-20"
                              }),
                              _vm._v(
                                " " + _vm._s(_vm.task.bioethics_comment) + " "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 col-xs-6 b-r" }, [
                  _c("strong", [_vm._v("Submission Date")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-muted" }, [
                    _vm._v(
                      _vm._s(_vm._f("momentDateTime")(_vm.task.created_at))
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("list-announcement")
                    ? _c("div", [
                        _c("div", [
                          _c("strong", [
                            _vm._v("Signed Researchers' Approval")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "ul",
                            {
                              staticClass: "m-t-10",
                              staticStyle: {
                                "list-style": "none",
                                padding: "0"
                              }
                            },
                            _vm._l(_vm.attachments, function(attachment) {
                              return _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "/abstracts/" +
                                        _vm.task.uuid +
                                        "/attachment/" +
                                        attachment.uuid +
                                        "/download?token=" +
                                        _vm.authToken
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-paperclip"
                                    }),
                                    _vm._v(
                                      " " + _vm._s(attachment.user_filename)
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          ),
                          _vm._v(" "),
                          !_vm.attachments.length
                            ? _c("li", { staticStyle: { color: "red" } }, [
                                _vm._v("!!!")
                              ])
                            : _vm._e()
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("list-announcement")
                    ? _c("strong", [_vm._v("Abstracts PDF")])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.hasPermission("list-announcement")
                    ? _c("p", { staticClass: "text-muted" }, [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "/abstracts/" +
                                _vm.task.uuid +
                                "/pdf/download?token=" +
                                _vm.authToken
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-paperclip" }),
                            _vm._v(
                              " " + _vm._s(_vm.getTaskNumber(_vm.task)) + ".pdf"
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 col-xs-6" }, [
                  _vm.hasPermission("list-announcement")
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.getTaskStatus(_vm.task), function(status) {
                            return _c(
                              "div",
                              {
                                staticClass: "ribbon-wrapper card",
                                staticStyle: {
                                  "margin-right": "-15px",
                                  "padding-top": "10px"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    class: [
                                      "ribbon",
                                      "ribbon-" + status.color,
                                      "ribbon-right"
                                    ]
                                  },
                                  [_vm._v(_vm._s(status.label))]
                                )
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _vm.task.is_recurring
                            ? _c(
                                "div",
                                {
                                  staticClass: "ribbon-wrapper card",
                                  staticStyle: {
                                    "margin-right": "-15px",
                                    "padding-top": "10px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: [
                                        "ribbon",
                                        "ribbon-danger",
                                        "ribbon-right"
                                      ],
                                      staticStyle: { cursor: "pointer" },
                                      on: { click: _vm.toggleRecurringPanel }
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-repeat" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.trans("task.recurring"))
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.task.is_archived
                            ? _c(
                                "div",
                                {
                                  staticClass: "ribbon-wrapper card",
                                  staticStyle: {
                                    "margin-right": "-15px",
                                    "padding-top": "10px"
                                  }
                                },
                                [
                                  _vm.task.user_id == _vm.getAuthUser("id") &&
                                  _vm.task.sign_off_status === "approved"
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "confirm",
                                                rawName: "v-confirm",
                                                value: {
                                                  ok: _vm.confirmToggleArchive(
                                                    _vm.task
                                                  )
                                                },
                                                expression:
                                                  "{ok: confirmToggleArchive(task)}"
                                              },
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "task.remove_from_archive"
                                                ),
                                                expression:
                                                  "trans('task.remove_from_archive')"
                                              }
                                            ],
                                            key: "archive_" + _vm.task.id,
                                            class: [
                                              "ribbon",
                                              "ribbon-warning",
                                              "ribbon-right"
                                            ],
                                            staticStyle: { cursor: "pointer" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.trans("task.archived"))
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            class: [
                                              "ribbon",
                                              "ribbon-warning",
                                              "ribbon-right"
                                            ]
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.trans("task.archived"))
                                            )
                                          ]
                                        )
                                      ]
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.task.user_id == _vm.getAuthUser("id")
                    ? _c("div", [
                        _vm.task.sign_off_status === "payment"
                          ? _c(
                              "div",
                              { staticStyle: { "text-align": "center" } },
                              [
                                _c("span", [_vm._v(" PAYMENT AMOUNT")]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("span", [
                                  _c("h2", [
                                    _vm._v(
                                      " " + _vm._s(_vm.task.price) + " EUR"
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _vm._v("Choose a Payment Method:")
                                ]),
                                _c("br"),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: {
                                    padding: "10px",
                                    cursor: "pointer",
                                    width: "180px"
                                  },
                                  attrs: {
                                    src:
                                      "https://docs.volt.io/wp-content/uploads/2023/06/Screenshot-2023-06-06-at-18.01.11-1024x571.png",
                                    "data-toggle": "modal",
                                    "data-target": "#paysera",
                                    alt: "Montonio sąskaita"
                                  }
                                }),
                                _vm._v(" "),
                                _c("img", {
                                  staticStyle: {
                                    cursor: "pointer",
                                    padding: "10px",
                                    width: "180px"
                                  },
                                  attrs: {
                                    src: "/uploads/images/bank-transfer.png",
                                    "data-toggle": "modal",
                                    "data-target": "#bank_transfer",
                                    alt: "Bank transfer"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal fade",
                                    attrs: {
                                      id: "credit_card",
                                      tabindex: "-1",
                                      role: "dialog",
                                      "aria-labelledby": "exampleModalLabel",
                                      "aria-hidden": "true"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "modal-dialog",
                                        attrs: { role: "document" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "modal-content" },
                                          [
                                            _vm._m(0),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "modal-body" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Abstract Number")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTaskNumber(
                                                          _vm.task
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v("Payment Amount")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.task.price) +
                                                        " EUR"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v("Payment Method")
                                                ]),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    "margin-bottom": "25px",
                                                    width: "50%"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "https://docs.volt.io/wp-content/uploads/2023/06/Screenshot-2023-06-06-at-18.01.11-1024x571.png",
                                                    alt: "Mokėjimo kortelės"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v(
                                                      '  PRESS "PAY ' +
                                                        _vm._s(_vm.task.price) +
                                                        ' EUR" TO CONTINUE '
                                                    )
                                                  ])
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "modal-footer" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary",
                                                    attrs: {
                                                      type: "button",
                                                      "data-dismiss": "modal"
                                                    }
                                                  },
                                                  [_vm._v("Close")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    attrs: {
                                                      href:
                                                        "/payment/credit_card/" +
                                                        _vm.task.uuid
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "PAY " +
                                                        _vm._s(_vm.task.price) +
                                                        " EUR"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal fade",
                                    attrs: {
                                      id: "paysera",
                                      tabindex: "-1",
                                      role: "dialog",
                                      "aria-labelledby": "exampleModalLabel",
                                      "aria-hidden": "true"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "modal-dialog",
                                        attrs: { role: "document" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "modal-content" },
                                          [
                                            _vm._m(1),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "modal-body" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Abstract Number")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTaskNumber(
                                                          _vm.task
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v("Payment Amount")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.task.price) +
                                                        " EUR"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v("Payment Method")
                                                ]),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    "margin-bottom": "25px",
                                                    width: "50%"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "https://docs.volt.io/wp-content/uploads/2023/06/Screenshot-2023-06-06-at-18.01.11-1024x571.png",
                                                    alt: "Mokėjimo kortelės"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _c("b", [
                                                    _vm._v(
                                                      '  PRESS "PAY ' +
                                                        _vm._s(_vm.task.price) +
                                                        ' EUR" TO CONTINUE '
                                                    )
                                                  ])
                                                ])
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "modal-footer" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-secondary",
                                                    attrs: {
                                                      type: "button",
                                                      "data-dismiss": "modal"
                                                    }
                                                  },
                                                  [_vm._v("Close")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "btn btn-primary",
                                                    attrs: {
                                                      href:
                                                        "/payment/paysera/" +
                                                        _vm.task.uuid
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "PAY " +
                                                        _vm._s(_vm.task.price) +
                                                        " EUR"
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "modal fade",
                                    attrs: {
                                      id: "bank_transfer",
                                      tabindex: "-1",
                                      role: "dialog",
                                      "aria-labelledby": "exampleModalLabel",
                                      "aria-hidden": "true"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "modal-dialog",
                                        attrs: { role: "document" }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "modal-content" },
                                          [
                                            _vm._m(2),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "modal-body" },
                                              [
                                                _c("strong", [
                                                  _vm._v("Abstract Number")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTaskNumber(
                                                          _vm.task
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v("Payment Amount")
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "p",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.task.price) +
                                                        " EUR"
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("strong", [
                                                  _vm._v("Payment Method")
                                                ]),
                                                _c("br"),
                                                _vm._v(" "),
                                                _c("img", {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  },
                                                  attrs: {
                                                    src:
                                                      "/uploads/images/bank-transfer.png",
                                                    alt: "Mokėjimo kortelės"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("p", [
                                                  _vm._v(
                                                    "Please make payment to the following bank account: "
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c(
                                                  "table",
                                                  { attrs: { border: "1" } },
                                                  [
                                                    _c("tbody", [
                                                      _vm._m(3),
                                                      _vm._v(" "),
                                                      _vm._m(4),
                                                      _vm._v(" "),
                                                      _vm._m(5),
                                                      _vm._v(" "),
                                                      _vm._m(6),
                                                      _vm._v(" "),
                                                      _vm._m(7),
                                                      _vm._v(" "),
                                                      _c("tr", [
                                                        _vm._m(8),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c("strong", [
                                                            _c("span", [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getTaskNumber(
                                                                      _vm.task
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ])
                                                          ])
                                                        ])
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("tr", [
                                                        _vm._m(9),
                                                        _vm._v(" "),
                                                        _c("td", [
                                                          _c("strong", [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.task.price
                                                                ) + " EUR"
                                                              )
                                                            ])
                                                          ])
                                                        ])
                                                      ])
                                                    ])
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._m(10)
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(11)
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasPermission("filter-abstracts")
        ? _c("div", { staticClass: "col-12 col-lg-4 col-md-4" }, [
            _c("div", { staticClass: "card" }, [
              _c(
                "div",
                { staticClass: "card-body" },
                [
                  _c("h4", { staticClass: "card-title" }, [
                    _vm._v("Abstract Reviewers")
                  ]),
                  _vm._v(" "),
                  _vm.task.user.length
                    ? _c("h6", { staticClass: "card-subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.trans("general.total_result_found", {
                              count: _vm.task.user.length
                            })
                          )
                        )
                      ])
                    : _c("h6", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("general.no_result_found")))
                      ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm._l(_vm.task.user, function(user) {
                    return _vm.task.user
                      ? _c("div", { staticClass: "d-flex flex-row m-b-20" }, [
                          _c("div", {}, [
                            _c("img", {
                              staticClass: "img-circle",
                              attrs: {
                                src: _vm.getAvatar(user),
                                alt: "user",
                                width: "70"
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "p-l-20" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-sm-10" }, [
                                _c("h4", { staticClass: "font-medium" }, [
                                  _c("b", [
                                    _vm._v(_vm._s(user.pivot.review_no) + ".")
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        user.profile.first_name +
                                          " " +
                                          user.profile.last_name
                                      ) +
                                      " (" +
                                      _vm._s(user.id) +
                                      ")"
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-sm-1" }, [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "confirm",
                                        rawName: "v-confirm",
                                        value: {
                                          ok: _vm.confirmRemoveReviewer(
                                            user,
                                            _vm.task
                                          )
                                        },
                                        expression:
                                          "{ok: confirmRemoveReviewer(user, task)}"
                                      },
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: "Remove reviewer",
                                        expression: "'Remove reviewer'"
                                      }
                                    ],
                                    staticStyle: {
                                      "text-align": "right",
                                      "margin-top": "-3px",
                                      color: "red",
                                      "margin-right": "0px"
                                    }
                                  },
                                  [_c("i", { staticClass: "fas fa-trash-alt" })]
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            user.profile.designation
                              ? _c("h6", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        user.profile.designation.name +
                                          " " +
                                          _vm.trans("general.in") +
                                          " " +
                                          user.profile.designation.department
                                            .name
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            user.pivot.review === 1
                              ? _c("h6", [_vm._v("First review")])
                              : _vm._e(),
                            _vm._v(" "),
                            user.pivot.review === 2
                              ? _c("h6", [_vm._v("Second review")])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", { staticClass: "time" }, [
                              _c("small", [
                                _vm._v(
                                  _vm._s(_vm.trans("task.assigned_at")) +
                                    " " +
                                    _vm._s(
                                      _vm._f("momentDateTime")(
                                        user.pivot.created_at
                                      )
                                    )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("br")
                          ])
                        ])
                      : _vm._e()
                  }),
                  _vm._v(" "),
                  !_vm.task.user
                    ? _c("div", { staticClass: "card-subtitle" }, [
                        _vm._v(_vm._s(_vm.trans("task.no_assigned_user")))
                      ])
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-lg-12 col-md-12" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "ul",
            {
              staticClass: "nav nav-tabs profile-tab",
              attrs: { role: "tablist" }
            },
            [
              _vm.hasPermission("list-announcement")
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link active",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#detail",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showDetailTab")
                          }
                        }
                      },
                      [_vm._v("Abstract")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("do-review")
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#question",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showQuestionTab")
                          }
                        }
                      },
                      [_vm._v("Review abstract")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.hasPermission("see-all-review")
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#rating",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showTaskRatingTab")
                          }
                        }
                      },
                      [_vm._v("Reviews Editor")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.task.show_reviews == 1 &&
              _vm.task.user_id == _vm.getAuthUser("id")
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#rating",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showTaskRatingTab")
                          }
                        }
                      },
                      [_vm._v("Reviews")]
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("li", { staticClass: "nav-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "nav-link",
                    attrs: { "data-toggle": "tab", href: "#note", role: "tab" },
                    on: {
                      click: function($event) {
                        return _vm.showHideTabs("showTaskNoteTab")
                      }
                    }
                  },
                  [_vm._v("Notes")]
                )
              ]),
              _vm._v(" "),
              _vm.hasAdminRole()
                ? _c("li", { staticClass: "nav-item" }, [
                    _c(
                      "a",
                      {
                        staticClass: "nav-link",
                        attrs: {
                          "data-toggle": "tab",
                          href: "#sign-off",
                          role: "tab"
                        },
                        on: {
                          click: function($event) {
                            return _vm.showHideTabs("showTaskSignOffTab")
                          }
                        }
                      },
                      [_vm._v("Activity list")]
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tab-content" }, [
            _vm.hasPermission("list-announcement")
              ? _c(
                  "div",
                  {
                    staticClass: "tab-pane active",
                    class: {
                      "tab-pane active": _vm.hasPermission("list-announcement")
                    },
                    attrs: { id: "detail", role: "tabpanel" }
                  },
                  [
                    _vm.tabs.showDetailTab
                      ? _c("div", { staticClass: "card-body" }, [
                          _c(
                            "div",
                            [
                              _vm._m(12),
                              _vm._v(" "),
                              _vm._l(_vm.task.authors, function(author) {
                                return _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "line-height": "20px",
                                      "margin-bottom": "10px"
                                    }
                                  },
                                  [
                                    _c("b", [
                                      _c("i", [
                                        _vm._v(_vm._s(author.scientific_degree))
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(author.firstname) +
                                          " " +
                                          _vm._s(author.lastname)
                                      )
                                    ]),
                                    _vm._v(" " + _vm._s(author.email)),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("i", [
                                      _vm._v(_vm._s(author.affiliation))
                                    ])
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.task.format === "Oral/Poster"
                            ? _c("div", [
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" }
                                }),
                                _vm._v(" "),
                                _vm._m(13),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.title)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(14),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.introduction)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(15),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: { innerHTML: _vm._s(_vm.task.aim) }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(16),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.methods)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(17),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.results)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(18),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.conclusions)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(19),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.references)
                                  }
                                })
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.task.format === "Case report"
                            ? _c("div", [
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" }
                                }),
                                _vm._v(" "),
                                _vm._m(20),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.title)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(21),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.introduction)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(22),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: { innerHTML: _vm._s(_vm.task.aim) }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(23),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.methods)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(24),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.conclusions)
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", { staticClass: "clearfix" }),
                                _vm._v(" "),
                                _vm._m(25),
                                _vm._v(" "),
                                _c("div", {
                                  staticStyle: { "margin-bottom": "25px" },
                                  domProps: {
                                    innerHTML: _vm._s(_vm.task.references)
                                  }
                                })
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.task.question_set
              ? _c(
                  "div",
                  {
                    staticClass: "tab-pane active",
                    attrs: { id: "question", role: "tabpanel" }
                  },
                  [
                    _vm.tabs.showQuestionTab
                      ? _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("h4", { staticClass: "card-title" }, [
                              _vm._v(
                                _vm._s(_vm.trans("task.answer_to_questions"))
                              )
                            ]),
                            _vm._v(" "),
                            !_vm.is_locked
                              ? [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-6" },
                                      [
                                        _vm.task.format === "Oral/Poster"
                                          ? _c("div", [
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-bottom": "25px"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm._m(26),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-bottom": "25px"
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.task.title
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(27),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.introduction
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(28),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(_vm.task.aim)
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(29),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.methods
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(30),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.results
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(31),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.conclusions
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(32),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.references
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.task.format === "Case report"
                                          ? _c("div", [
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-bottom": "25px"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _vm._m(33),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticStyle: {
                                                  "margin-bottom": "25px"
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.task.title
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(34),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.introduction
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(35),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(_vm.task.aim)
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(36),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.methods
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(37),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.conclusions
                                                      )
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "clearfix"
                                              }),
                                              _vm._v(" "),
                                              _vm._m(38),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "25px"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.ToText(
                                                        _vm.task.references
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-12 col-sm-6" },
                                      [
                                        _vm.task.review_no == 2
                                          ? _c(
                                              "div",
                                              [
                                                _c("h3", [
                                                  _vm._v("Your reviews:")
                                                ]),
                                                _vm._v(" "),
                                                _c("br"),
                                                _vm._v(" "),
                                                _vm._l(_vm.reviews, function(
                                                  answer
                                                ) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-sm-12"
                                                    },
                                                    [
                                                      _vm.getAuthUser("id") ==
                                                      answer.user_id
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass: "row"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-3 col-sm-3"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      answer.question
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              answer.question ===
                                                              "Abstract is accepted/ declined / should be corrected"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-3 col-sm-3"
                                                                    },
                                                                    [
                                                                      answer.answer ==
                                                                      "1"
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-success m-r-20"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-check-circle"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Accepted"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      answer.answer ==
                                                                      "2"
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-warning m-r-20"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-question-circle"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Should be corrected"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      answer.answer ==
                                                                      "0"
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-danger"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-times-circle m-r-20"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Declined "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                : answer.question ===
                                                                  "I recommend this abstract for oral presentation / poster presentation"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-3 col-sm-3"
                                                                    },
                                                                    [
                                                                      answer.answer ==
                                                                      "1"
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "far fa-file-powerpoint"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Presentation "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      answer.answer ==
                                                                      "2"
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-question-circle"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Unclear"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      answer.answer ==
                                                                      "0"
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "far fa-file-word"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Poster "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-3 col-sm-3"
                                                                    },
                                                                    [
                                                                      answer.answer ==
                                                                      "1"
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-success m-r-20"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-check-circle"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Yes"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      answer.answer ==
                                                                      "2"
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-warning m-r-20"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-question-circle"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " Should be corrected"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      answer.answer ==
                                                                      "0"
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "text-danger"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fas fa-times-circle m-r-20"
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " No "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  ),
                                                              _vm._v(" "),
                                                              answer.description
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-5 col-sm-5"
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-comments",
                                                                        staticStyle: {
                                                                          "margin-right":
                                                                            "10px"
                                                                        }
                                                                      }),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("i", [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            answer.description
                                                                          )
                                                                        )
                                                                      ])
                                                                    ]
                                                                  )
                                                                : _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "col-5 col-sm-5"
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-comments",
                                                                        staticStyle: {
                                                                          "margin-right":
                                                                            "10px"
                                                                        }
                                                                      }),
                                                                      _vm._v(
                                                                        " -"
                                                                      )
                                                                    ]
                                                                  )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                }),
                                                _vm._v(" "),
                                                _c("hr"),
                                                _vm._v(" "),
                                                _c("br")
                                              ],
                                              2
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "form",
                                          {
                                            on: {
                                              submit: function($event) {
                                                $event.preventDefault()
                                                return _vm.submit($event)
                                              },
                                              keydown: function($event) {
                                                return _vm.questionForm.errors.clear(
                                                  $event.target.name
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._l(
                                              _vm.questionForm.answers,
                                              function(answer) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-sm-12"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group"
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              attrs: { for: "" }
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    answer.question
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("i", [
                                                            _c("div", {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  answer.comment
                                                                )
                                                              }
                                                            })
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "radio radio-info text-center",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "20px"
                                                              }
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      answer.answer,
                                                                    expression:
                                                                      "answer.answer"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  value: "yes",
                                                                  id:
                                                                    "answer_" +
                                                                    answer.id +
                                                                    "_1",
                                                                  name:
                                                                    "answer_" +
                                                                    answer.id
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    answer.answer ===
                                                                    "1",
                                                                  checked: _vm._q(
                                                                    answer.answer,
                                                                    "yes"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.questionForm.errors.clear(
                                                                      "answer_" +
                                                                        answer.id
                                                                    )
                                                                  },
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      answer,
                                                                      "answer",
                                                                      "yes"
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "m-r-20",
                                                                  attrs: {
                                                                    for:
                                                                      "answer_" +
                                                                      answer.id +
                                                                      "_1"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.trans(
                                                                          "list.yes"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      answer.answer,
                                                                    expression:
                                                                      "answer.answer"
                                                                  }
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  value: "no",
                                                                  id:
                                                                    "answer_" +
                                                                    answer.id +
                                                                    "_0",
                                                                  name:
                                                                    "answer_" +
                                                                    answer.id
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    answer.answer ===
                                                                    "0",
                                                                  checked: _vm._q(
                                                                    answer.answer,
                                                                    "no"
                                                                  )
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.questionForm.errors.clear(
                                                                      "answer_" +
                                                                        answer.id
                                                                    )
                                                                  },
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      answer,
                                                                      "answer",
                                                                      "no"
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "15px"
                                                                  },
                                                                  attrs: {
                                                                    for:
                                                                      "answer_" +
                                                                      answer.id +
                                                                      "_0"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.trans(
                                                                          "list.no"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _vm.task
                                                                .review_no == 1
                                                                ? _c("input", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          answer.answer,
                                                                        expression:
                                                                          "answer.answer"
                                                                      }
                                                                    ],
                                                                    attrs: {
                                                                      type:
                                                                        "radio",
                                                                      value:
                                                                        "shouldbecorrected",
                                                                      id:
                                                                        "answer_" +
                                                                        answer.id +
                                                                        "_2",
                                                                      name:
                                                                        "answer_" +
                                                                        answer.id
                                                                    },
                                                                    domProps: {
                                                                      checked:
                                                                        answer.answer ===
                                                                        "2",
                                                                      checked: _vm._q(
                                                                        answer.answer,
                                                                        "shouldbecorrected"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.questionForm.errors.clear(
                                                                          "answer_" +
                                                                            answer.id
                                                                        )
                                                                      },
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.$set(
                                                                          answer,
                                                                          "answer",
                                                                          "shouldbecorrected"
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                : _vm._e(),
                                                              _vm._v(" "),
                                                              _vm.task
                                                                .review_no == 1
                                                                ? _c(
                                                                    "label",
                                                                    {
                                                                      attrs: {
                                                                        for:
                                                                          "answer_" +
                                                                          answer.id +
                                                                          "_2"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " Should be corrected  "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("show-error", {
                                                            attrs: {
                                                              "form-name":
                                                                _vm.questionForm,
                                                              "prop-name":
                                                                "answer_" +
                                                                answer.id
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "col-12 col-sm-12"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group"
                                                        },
                                                        [
                                                          _c(
                                                            "autosize-textarea",
                                                            {
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                rows: "2",
                                                                placeholder:
                                                                  "Comments",
                                                                name:
                                                                  "description"
                                                              },
                                                              model: {
                                                                value:
                                                                  answer.description,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    answer,
                                                                    "description",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "answer.description"
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "form-group" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-info pull-right",
                                                    attrs: { type: "submit" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.trans(
                                                          "general.save"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "alert alert-success",
                                      attrs: { role: "alert" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Your review was successfully saved!\n                              "
                                      )
                                    ]
                                  )
                                ]
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "sign-off", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskSignOffTab
                  ? _c("div", { staticClass: "card-body" })
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "rating", role: "tabpanel" }
              },
              [
                _c(
                  "div",
                  { staticClass: "row", staticStyle: { padding: "15px" } },
                  [
                    _vm._l(_vm.reviews, function(answer) {
                      return _c("div", { staticClass: "col-12 col-sm-12" }, [
                        _vm.hasPermission("see-all-review")
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-2 col-sm-2" }, [
                                _c("b", [
                                  _vm._v(
                                    "Reviewer No " +
                                      _vm._s(answer.review_no) +
                                      " (" +
                                      _vm._s(answer.user_id) +
                                      ")"
                                  )
                                ])
                              ]),
                              _vm._v(" "),
                              answer.question ===
                              "Abstract is accepted/ declined / should be corrected"
                                ? _c("div", { staticClass: "col-2 col-sm-2" }, [
                                    answer.answer == "1"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-success m-r-20"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-check-circle"
                                            }),
                                            _vm._v(" Accepted")
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    answer.answer == "2"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-warning m-r-20"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-question-circle"
                                            }),
                                            _vm._v(" Should be corrected")
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    answer.answer == "0"
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-times-circle m-r-20"
                                            }),
                                            _vm._v(" Declined ")
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                : answer.question ===
                                  "I recommend this abstract for oral presentation / poster presentation"
                                ? _c("div", { staticClass: "col-2 col-sm-2" }, [
                                    answer.answer == "1"
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "far fa-file-powerpoint"
                                          }),
                                          _vm._v(" Presentation ")
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    answer.answer == "2"
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-question-circle"
                                          }),
                                          _vm._v(" Unclear")
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    answer.answer == "0"
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "far fa-file-word"
                                          }),
                                          _vm._v(" Poster ")
                                        ])
                                      : _vm._e()
                                  ])
                                : _c("div", { staticClass: "col-2 col-sm-2" }, [
                                    answer.answer == "1"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-success m-r-20"
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-check-circle"
                                            }),
                                            _vm._v(" Yes")
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    answer.answer == "2"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "text-warning m-r-20"
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-question-circle"
                                            }),
                                            _vm._v(" Should be corrected")
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    answer.answer == "0"
                                      ? _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-times-circle m-r-20"
                                            }),
                                            _vm._v(" No ")
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 col-sm-3" }, [
                                _vm._v(_vm._s(answer.question))
                              ]),
                              _vm._v(" "),
                              answer.description
                                ? _c("div", { staticClass: "col-5 col-sm-5" }, [
                                    _c("i", {
                                      staticClass: "fas fa-comments",
                                      staticStyle: { "margin-right": "10px" }
                                    }),
                                    _vm._v(" "),
                                    _c("i", [
                                      _vm._v(_vm._s(answer.description))
                                    ])
                                  ])
                                : _c("div", { staticClass: "col-5 col-sm-5" }, [
                                    _c("i", {
                                      staticClass: "fas fa-comments",
                                      staticStyle: { "margin-right": "10px" }
                                    }),
                                    _vm._v(" -")
                                  ])
                            ])
                          : _c("div", { staticClass: "row" }, [
                              answer.question ===
                              "Abstract is accepted/ declined / should be corrected"
                                ? _c("div", { staticClass: "col-2 col-sm-2" })
                                : answer.question ===
                                  "I recommend this abstract for oral presentation / poster presentation"
                                ? _c("div", { staticClass: "col-2 col-sm-2" })
                                : _c("div", { staticClass: "col-2 col-sm-2" }, [
                                    _c("b", [
                                      _vm._v(
                                        "Reviewer No " +
                                          _vm._s(answer.review_no) +
                                          " (" +
                                          _vm._s(answer.user_id) +
                                          ")"
                                      )
                                    ])
                                  ]),
                              _vm._v(" "),
                              answer.question ===
                              "Abstract is accepted/ declined / should be corrected"
                                ? _c("div", { staticClass: "col-5 col-sm-3" })
                                : answer.question ===
                                  "I recommend this abstract for oral presentation / poster presentation"
                                ? _c("div", { staticClass: "col-5 col-sm-3" })
                                : _c("div", { staticClass: "col-4 col-sm-3" }, [
                                    _vm._v(_vm._s(answer.question))
                                  ]),
                              _vm._v(" "),
                              answer.question ===
                              "Abstract is accepted/ declined / should be corrected"
                                ? _c("div", { staticClass: "col-5 col-sm-3" })
                                : answer.question ===
                                  "I recommend this abstract for oral presentation / poster presentation"
                                ? _c("div", { staticClass: "col-5 col-sm-3" })
                                : _c("div", { staticClass: "col-6 col-sm-7" }, [
                                    _c("i", {
                                      staticClass: "fas fa-comments",
                                      staticStyle: { "margin-right": "10px" }
                                    }),
                                    _vm._v(" "),
                                    _c("i", [
                                      _vm._v(_vm._s(answer.description))
                                    ])
                                  ])
                            ])
                      ])
                    }),
                    _vm._v(" "),
                    _vm.reviews.length === 0
                      ? _c("div", { staticClass: "col-12 col-sm-12" }, [
                          _c(
                            "div",
                            {
                              staticClass: "alert alert-warning",
                              attrs: { role: "alert" }
                            },
                            [
                              _vm._v(
                                "\n                          No results found\n                          "
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "sub-task", role: "tabpanel" }
              },
              [
                _vm.tabs.showSubTaskTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [
                        _c("sub-task", {
                          attrs: { uuid: _vm.uuid },
                          on: { updateProgress: _vm.getTask }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "comment", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskCommentTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-comment", { attrs: { uuid: _vm.uuid } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "note", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskNoteTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-note", { attrs: { uuid: _vm.uuid } })],
                      1
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tab-pane",
                attrs: { id: "attachment", role: "tabpanel" }
              },
              [
                _vm.tabs.showTaskAttachmentTab
                  ? _c(
                      "div",
                      { staticClass: "card-body" },
                      [_c("task-attachment", { attrs: { uuid: _vm.uuid } })],
                      1
                    )
                  : _vm._e()
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [_vm._v("PAYMENT BY CREDIT CARD")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [_vm._v("PAYMENT BY MONTONIO (E-BANKING)")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [
          _vm._v(
            "PAYMENT BY BANK TRANSFER (ONLY FOR INTERNATIONAL PARTICIPANTS)"
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Recipient")]),
      _vm._v(" "),
      _c("td", [_vm._v("LSMU studentų mokslinė draugija")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Recipient account")]),
      _vm._v(" "),
      _c("td", [_c("span", [_vm._v("LT217300010133733966")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Bank name")]),
      _vm._v(" "),
      _c("td", [_c("span", [_vm._v('„Swedbank", AB')])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("SWIFT")]),
      _vm._v(" "),
      _c("td", [_c("span", [_vm._v("HABALT22")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Bank address")]),
      _vm._v(" "),
      _c("td", [_c("span", [_vm._v("Konstitucijos 20A, Vilnius")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _c("span", [
        _vm._v(" Purpose "),
        _c("span", { staticStyle: { color: "rgb(255,0,0)" } }, [_vm._v("*")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_c("span", [_vm._v("Amount")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("strong", [
        _c("span", { staticStyle: { color: "rgb(255,0,0)" } }, [
          _vm._v("* Very important")
        ])
      ]),
      _vm._v(" ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-dismiss": "modal" }
        },
        [_vm._v("Close")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Authors")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Abstract Topic")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Introduction")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Aim")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Methods")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Results")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Conclusions")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("References")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Title")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Introduction")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Case presentation")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Discussion")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Conclusions")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("References")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Abstract Topic")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Introduction")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Aim")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Methods")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Results")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Conclusions")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("References")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Abstract Topic")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Introduction")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Case presentation")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Discussion")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("Conclusions")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h5", [_c("b", [_vm._v("References")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }