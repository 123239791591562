var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade task-config",
      staticStyle: { display: "none" },
      attrs: {
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "taskConfig",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-lg" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h4",
              { staticClass: "modal-title", attrs: { id: "taskConfig" } },
              [
                _vm._v(
                  _vm._s(_vm.trans("task.configuration")) + "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-hidden": "true"
                }
              },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.submit($event)
                  },
                  keydown: function($event) {
                    return _vm.taskConfigForm.errors.clear($event.target.name)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "" } }, [
                      _vm._v(
                        "Mokestis: " + _vm._s(_vm.taskConfigForm.price) + " EUR"
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.price,
                            expression: "taskConfigForm.price"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "25",
                          id: "price_20",
                          name: "price"
                        },
                        domProps: {
                          checked: _vm.taskConfigForm.price === "20",
                          checked: _vm._q(_vm.taskConfigForm.price, "25")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.taskConfigForm, "price", "25")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "price_20" } }, [
                        _vm._v("Ne LSMU SMD narys - 25 EUR ")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.price,
                            expression: "taskConfigForm.price"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "30",
                          id: "price_30",
                          name: "price"
                        },
                        domProps: {
                          checked: _vm.taskConfigForm.price === "30",
                          checked: _vm._q(_vm.taskConfigForm.price, "30")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.taskConfigForm, "price", "30")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "price_30" } }, [
                        _vm._v("Antra ir kitos tezės - 30 EUR ")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "radio radio-info" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.taskConfigForm.price,
                            expression: "taskConfigForm.price"
                          }
                        ],
                        attrs: {
                          type: "radio",
                          value: "15",
                          id: "price_10",
                          name: "price"
                        },
                        domProps: {
                          checked: _vm.taskConfigForm.price === "10",
                          checked: _vm._q(_vm.taskConfigForm.price, "15")
                        },
                        on: {
                          change: function($event) {
                            return _vm.$set(_vm.taskConfigForm, "price", "15")
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "price_10" } }, [
                        _vm._v("LSMU SMD narys - 15 EUR ")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("show-error", {
                      attrs: {
                        "form-name": _vm.taskConfigForm,
                        "prop-name": "price"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Tezės statusas")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.status,
                          expression: "taskConfigForm.status"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "atsauktas",
                        id: "rejected",
                        name: "status"
                      },
                      domProps: {
                        checked: _vm.taskConfigForm.status === "rejected",
                        checked: _vm._q(_vm.taskConfigForm.status, "atsauktas")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.taskConfigForm,
                            "status",
                            "atsauktas"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "rejected" } }, [
                      _vm._v(" Rejected - ATMESTA ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.status,
                          expression: "taskConfigForm.status"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "payment",
                        id: "payment",
                        name: "status"
                      },
                      domProps: {
                        checked: _vm.taskConfigForm.status === "payment",
                        checked: _vm._q(_vm.taskConfigForm.status, "payment")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.taskConfigForm,
                            "status",
                            "payment"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "payment" } }, [
                      _vm._v(" Waiting for payment - LAUKIAMA APMOKĖJIMO")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.status,
                          expression: "taskConfigForm.status"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "paid",
                        id: "paid",
                        name: "status"
                      },
                      domProps: {
                        checked: _vm.taskConfigForm.status === "paid",
                        checked: _vm._q(_vm.taskConfigForm.status, "paid")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.taskConfigForm, "status", "paid")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "paid" } }, [
                      _vm._v(" Already paid - APMOKĖTA")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.status,
                          expression: "taskConfigForm.status"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "approved",
                        id: "approved",
                        name: "status"
                      },
                      domProps: {
                        checked: _vm.taskConfigForm.status === "approved",
                        checked: _vm._q(_vm.taskConfigForm.status, "approved")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.taskConfigForm,
                            "status",
                            "approved"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "approved" } }, [
                      _vm._v(" Approved - PRIIMTA")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.status,
                          expression: "taskConfigForm.status"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "shouldbecorrected",
                        id: "shouldbecorrected",
                        name: "status"
                      },
                      domProps: {
                        checked:
                          _vm.taskConfigForm.status === "shouldbecorrected",
                        checked: _vm._q(
                          _vm.taskConfigForm.status,
                          "shouldbecorrected"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.taskConfigForm,
                            "status",
                            "shouldbecorrected"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "shouldbecorrected" } }, [
                      _vm._v(" Should be corrected - REIKALAUJA KOREKCIJŲ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Send Mail?")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.mail,
                          expression: "taskConfigForm.mail"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "taip",
                        id: "sendmailyes",
                        name: "mail"
                      },
                      domProps: {
                        checked: _vm._q(_vm.taskConfigForm.mail, "taip")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.taskConfigForm, "mail", "taip")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "sendmailyes" } }, [
                      _vm._v(" Yes ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.mail,
                          expression: "taskConfigForm.mail"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "ne",
                        id: "sendmailno",
                        name: "mail"
                      },
                      domProps: {
                        checked: _vm._q(_vm.taskConfigForm.mail, "ne")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.taskConfigForm, "mail", "ne")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "sendmailno" } }, [
                      _vm._v(" No ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v("Review information")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.review_no,
                          expression: "taskConfigForm.review_no"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "1",
                        id: "FirstReview",
                        name: "review_no"
                      },
                      domProps: {
                        checked: _vm._q(_vm.taskConfigForm.review_no, "1")
                      },
                      on: {
                        click: function($event) {
                          _vm.showReview1Panel = !_vm.showReview1Panel
                        },
                        change: function($event) {
                          return _vm.$set(_vm.taskConfigForm, "review_no", "1")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "FirstReview" } }, [
                      _vm._v(" First Review ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm.showReview1Panel
                    ? _c(
                        "div",
                        { staticClass: "radio radio-info" },
                        [
                          _c("v-select", {
                            attrs: {
                              label: "name",
                              "track-by": "id",
                              name: "user_id1",
                              id: "user_id1",
                              options: _vm.users,
                              limit: 3,
                              placeholder: _vm.trans("user.select_user"),
                              multiple: true,
                              "close-on-select": false,
                              "clear-on-select": false,
                              "hide-selected": true
                            },
                            on: {
                              select: _vm.onUserSelect,
                              remove: _vm.onUserRemove
                            },
                            model: {
                              value: _vm.taskConfigForm.selected_users1,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.taskConfigForm,
                                  "selected_users1",
                                  $$v
                                )
                              },
                              expression: "taskConfigForm.selected_users1"
                            }
                          }),
                          _c("br")
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.review_no,
                          expression: "taskConfigForm.review_no"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "2",
                        id: "SecondReview",
                        name: "review_no"
                      },
                      domProps: {
                        checked: _vm._q(_vm.taskConfigForm.review_no, "2")
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(_vm.taskConfigForm, "review_no", "2")
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "SecondReview" } }, [
                      _vm._v(" Second Review ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("div", { staticClass: "radio radio-info" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taskConfigForm.sendletter1,
                          expression: "taskConfigForm.sendletter1"
                        }
                      ],
                      attrs: {
                        type: "radio",
                        value: "sendfirst",
                        id: "sendletter1",
                        name: "sendletter1"
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.taskConfigForm.sendletter1,
                          "sendfirst"
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.$set(
                            _vm.taskConfigForm,
                            "sendletter1",
                            "sendfirst"
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { for: "sendletter1" },
                        on: {
                          click: function($event) {
                            _vm.showSend1Panel = !_vm.showSend1Panel
                          }
                        }
                      },
                      [_vm._v(" Send Letter ? ")]
                    )
                  ]),
                  _vm._v(" "),
                  _vm.showSend1Panel
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12 col-md-12" }, [
                          _vm._v(
                            '\n                      Nepamirškite pakeisti tezės statuso prieš siųsdami laišką!!! (Taikoma "Jei reikalaujama korekcijų", "Jei reikalaujama minimalių korekcijų", "Jei atmetama")\n                    '
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("div", { staticClass: "radio radio-info" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskConfigForm.kokilaiskasiusti,
                                  expression: "taskConfigForm.kokilaiskasiusti"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                value: "reikiakorekciju",
                                id: "reikiakorekciju",
                                name: "kokilaiskasiusti"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.taskConfigForm.kokilaiskasiusti,
                                  "reikiakorekciju"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.taskConfigForm,
                                    "kokilaiskasiusti",
                                    "reikiakorekciju"
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "reikiakorekciju" } }, [
                              _vm._v(" Jei reikalaujama korekcijų ")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "radio radio-info" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskConfigForm.kokilaiskasiusti,
                                  expression: "taskConfigForm.kokilaiskasiusti"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                value: "reikiaminkorekciju",
                                id: "reikiaminkorekciju",
                                name: "kokilaiskasiusti"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.taskConfigForm.kokilaiskasiusti,
                                  "reikiaminkorekciju"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.taskConfigForm,
                                    "kokilaiskasiusti",
                                    "reikiaminkorekciju"
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "reikiaminkorekciju" } },
                              [_vm._v(" Jei reikalaujama minimalių korekcijų ")]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "radio radio-info" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskConfigForm.kokilaiskasiusti,
                                  expression: "taskConfigForm.kokilaiskasiusti"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                value: "nereikiakorekciju",
                                id: "nereikiakorekciju",
                                name: "kokilaiskasiusti"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.taskConfigForm.kokilaiskasiusti,
                                  "nereikiakorekciju"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.taskConfigForm,
                                    "kokilaiskasiusti",
                                    "nereikiakorekciju"
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              { attrs: { for: "nereikiakorekciju" } },
                              [
                                _vm._v(
                                  " Jei komentarai nereikalauja korekcijų "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "radio radio-info" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.taskConfigForm.kokilaiskasiusti,
                                  expression: "taskConfigForm.kokilaiskasiusti"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                value: "atmetam",
                                id: "atmetam",
                                name: "kokilaiskasiusti"
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.taskConfigForm.kokilaiskasiusti,
                                  "atmetam"
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.taskConfigForm,
                                    "kokilaiskasiusti",
                                    "atmetam"
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("label", { attrs: { for: "atmetam" } }, [
                              _vm._v(" Jei atmetama ")
                            ])
                          ])
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info waves-effect waves-light",
                    attrs: { type: "submit" }
                  },
                  [_vm._v(_vm._s(_vm.trans("general.save")))]
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }