<template>
    <div class="modal fade task-config" tabindex="-1" role="dialog" aria-labelledby="taskConfig" aria-hidden="true" style="display: none;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title" id="taskConfig">{{trans('task.configuration')}}
                    </h4>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="submit" @keydown="taskConfigForm.errors.clear($event.target.name)">

                      <div class="form-group">
                          <label for="">Mokestis: {{taskConfigForm.price}} EUR</label>
                          <div class="radio radio-info">
                              <input type="radio" value="25" id="price_20" v-model="taskConfigForm.price" :checked="taskConfigForm.price === '20'" name="price">
                              <label for="price_20">Ne LSMU SMD narys - 25 EUR </label>
                          </div>
                          <div class="radio radio-info">
                              <input type="radio" value="30" id="price_30" v-model="taskConfigForm.price" :checked="taskConfigForm.price === '30'" name="price">
                              <label for="price_30">Antra ir kitos tezės - 30 EUR </label>
                          </div>
                          <div class="radio radio-info">
                              <input type="radio" value="15" id="price_10" v-model="taskConfigForm.price" :checked="taskConfigForm.price === '10'" name="price">
                              <label for="price_10">LSMU SMD narys - 15 EUR </label>
                          </div>
                          <show-error :form-name="taskConfigForm" prop-name="price"></show-error>
                      </div>
                      <div class="form-group">
                        <label for="">Tezės statusas</label>
                        <div class="radio radio-info">
                        <input type="radio" value="atsauktas" id="rejected" v-model="taskConfigForm.status" :checked="taskConfigForm.status === 'rejected'" name="status">
                        <label for="rejected"> Rejected - ATMESTA </label>
                      </div>
                      <div class="radio radio-info">
                        <input type="radio" value="payment" id="payment" v-model="taskConfigForm.status" :checked="taskConfigForm.status === 'payment'" name="status">
                        <label for="payment"> Waiting for payment - LAUKIAMA APMOKĖJIMO</label>
                      </div>
                      <div class="radio radio-info">
                        <input type="radio" value="paid" id="paid" v-model="taskConfigForm.status" :checked="taskConfigForm.status === 'paid'" name="status">
                        <label for="paid"> Already paid - APMOKĖTA</label>
                      </div>
                      <div class="radio radio-info">
                        <input type="radio" value="approved" id="approved" v-model="taskConfigForm.status" :checked="taskConfigForm.status === 'approved'" name="status">
                        <label for="approved"> Approved - PRIIMTA</label>
                      </div>
                      <div class="radio radio-info">
                        <input type="radio" value="shouldbecorrected" id="shouldbecorrected" v-model="taskConfigForm.status" :checked="taskConfigForm.status === 'shouldbecorrected'" name="status">
                        <label for="shouldbecorrected"> Should be corrected - REIKALAUJA KOREKCIJŲ</label>
                      </div>
                      </div>

                      <div class="form-group">
                        <label for="">Send Mail?</label>
                        <div class="radio radio-info">
                        <input type="radio" value="taip" id="sendmailyes" v-model="taskConfigForm.mail"  name="mail">
                        <label for="sendmailyes"> Yes </label>
                      </div>
                      <div class="radio radio-info">
                        <input type="radio" value="ne" id="sendmailno" v-model="taskConfigForm.mail" name="mail">
                        <label for="sendmailno"> No </label>
                      </div>
                      </div>

                      <div class="form-group">
                        <label for="">Review information</label>
                        <div class="radio radio-info">
                        <input type="radio" value="1" id="FirstReview" @click="showReview1Panel = !showReview1Panel" v-model="taskConfigForm.review_no"  name="review_no">
                        <label for="FirstReview"> First Review </label>
                      </div>
                      </br>
                      <div class="radio radio-info" v-if="showReview1Panel">
                        <v-select label="name" track-by="id" v-model="taskConfigForm.selected_users1" name="user_id1" id="user_id1" :options="users"  @select="onUserSelect" @remove="onUserRemove" :limit="3" :placeholder="trans('user.select_user')" :multiple="true" :close-on-select="false" :clear-on-select="false" :hide-selected="true" >
                        </v-select></br>
                      </div>
                      <div class="radio radio-info">
                        <input type="radio" value="2" id="SecondReview" v-model="taskConfigForm.review_no" name="review_no">
                        <label for="SecondReview"> Second Review </label>
                      </div>
                      </div>
                      <div class="form-group">
                      <div class="radio radio-info">
                        <input type="radio" value="sendfirst" id="sendletter1" v-model="taskConfigForm.sendletter1" name="sendletter1">
                        <label for="sendletter1" @click="showSend1Panel = !showSend1Panel"> Send Letter ? </label>
                      </div>
                      <div class="row"  v-if="showSend1Panel">
                        <div class="col-12 col-md-12" >
                          Nepamirškite pakeisti tezės statuso prieš siųsdami laišką!!! (Taikoma "Jei reikalaujama korekcijų", "Jei reikalaujama minimalių korekcijų", "Jei atmetama")
                        </br>

                          <div class="radio radio-info">
                            <input type="radio" value="reikiakorekciju" id="reikiakorekciju" v-model="taskConfigForm.kokilaiskasiusti" name="kokilaiskasiusti">
                            <label for="reikiakorekciju"> Jei reikalaujama korekcijų </label>
                          </div>
                          <div class="radio radio-info">
                            <input type="radio" value="reikiaminkorekciju" id="reikiaminkorekciju" v-model="taskConfigForm.kokilaiskasiusti" name="kokilaiskasiusti">
                            <label for="reikiaminkorekciju"> Jei reikalaujama minimalių korekcijų </label>
                          </div>
                          <div class="radio radio-info">
                            <input type="radio" value="nereikiakorekciju" id="nereikiakorekciju" v-model="taskConfigForm.kokilaiskasiusti" name="kokilaiskasiusti">
                            <label for="nereikiakorekciju"> Jei komentarai nereikalauja korekcijų </label>
                          </div>
                          <div class="radio radio-info">
                            <input type="radio" value="atmetam" id="atmetam" v-model="taskConfigForm.kokilaiskasiusti" name="kokilaiskasiusti">
                            <label for="atmetam"> Jei atmetama </label>
                          </div>
                        </div>
                        </div>
                      </div>

                        <button type="submit" class="btn btn-info waves-effect waves-light">{{trans('general.save')}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-multiselect'
import htmlEditor from '@components/html-editor'
    export default {
        components:{vSelect, htmlEditor},
        data() {
            return {
              showReview1Panel: false,
              showSend1Panel: false,
              showLetter1Panel: false,
              showLetter2Panel: false,
              showLetter3Panel: false,
              showLetter4Panel: false,
              users: [],
                taskConfigForm: new Form({
                    progress_type : 'manual',
                    rating_type: 'task_based',
                    price: 'Nepriskirta',
                    status: '',
                    mail: '',
                    sendletter1: '',
                    kokilaiskasiusti: '',
                    review_no: '',
                    user_id1: [],
                    selected_users1: []
                },false),
            }
        },
        props: ['uuid','task'],
        mounted() {
          axios.get('/api/task/pre-requisite')
              .then(response => response.data)
              .then(response => {
                  this.users = response.users;
                  this.task_categories = response.task_categories;
                  this.task_priorities = response.task_priorities;
                  this.question_sets = response.question_sets;
              })
              .catch(error => {
                  helper.showDataErrorMsg(error);
              });
        },
        methods: {
            submit(){
                this.taskConfigForm.post('/api/task/'+this.uuid+'/config')
                    .then(response => {
                        toastr.success(response.message);
                        this.closeModal();
                        this.$emit('completed');

                    })
                    .catch(error => {
                        helper.showErrorMsg(error);
                    });
            },
            onUserSelect(selectedOption){
                this.taskConfigForm.user_id1.push(selectedOption.id);
            },
            onUserRemove(removedOption){
                this.taskConfigForm.user_id1.splice(this.taskConfigForm.user_id1.indexOf(removedOption.id), 1);
            },
            closeModal(){
                $('.task-config').modal('hide');
            },
            ReikiaKorekciju(){
                this.taskConfigForm.firstreviewtext.push('<h1>ka</h1>');
            }
        },
        watch: {
            task(task){
                this.taskConfigForm.progress_type = task.progress_type;
                this.taskConfigForm.rating_type = task.rating_type;
                this.taskConfigForm.price = task.price;
                this.taskConfigForm.status = task.sign_off_status;
                this.taskConfigForm.user_id = task.user_id;
            }
        }
    }
</script>
