var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "pull-right btn btn-sm btn-rounded btn-success",
          attrs: { "data-toggle": "modal", "data-target": ".task-note-form" }
        },
        [_vm._v(_vm._s(_vm.trans("task.add_new_note")))]
      ),
      _vm._v(" "),
      _c("task-note-form", {
        attrs: { uuid: _vm.uuid, nuuid: _vm.editNoteUuid },
        on: { completed: _vm.getNotes, loaded: _vm.reset }
      }),
      _vm._v(" "),
      _c("task-note-detail", {
        attrs: { uuid: _vm.uuid, nuuid: _vm.showNoteUuid },
        on: { loaded: _vm.reset }
      }),
      _vm._v(" "),
      _c("h4", { staticClass: "card-title m-t-20" }, [
        _vm._v(_vm._s(_vm.trans("task.task_note_list")))
      ]),
      _vm._v(" "),
      _vm.task_notes
        ? _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(
              _vm._s(
                _vm.trans("general.total_result_found", {
                  count: _vm.task_notes.total
                })
              )
            )
          ])
        : _c("h6", { staticClass: "card-subtitle" }, [
            _vm._v(_vm._s(_vm.trans("general.no_result_found")))
          ]),
      _vm._v(" "),
      _vm.task_notes.total
        ? _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.task_note_title")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.visibility")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.owner")))]),
                  _vm._v(" "),
                  _c("th", [_vm._v(_vm._s(_vm.trans("task.created_at")))]),
                  _vm._v(" "),
                  _c("th", { staticClass: "table-option" }, [
                    _vm._v(_vm._s(_vm.trans("general.action")))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.task_notes.data, function(task_note) {
                  return _c("tr", [
                    _c("td", {
                      domProps: { textContent: _vm._s(task_note.title) }
                    }),
                    _vm._v(" "),
                    _c("td", {
                      domProps: {
                        innerHTML: _vm._s(_vm.getNoteVisibility(task_note))
                      }
                    }),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          task_note.user.profile.first_name +
                            " " +
                            task_note.user.profile.last_name
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(_vm._f("momentDateTime")(task_note.created_at))
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "table-option" }, [
                      _c(
                        "div",
                        { staticClass: "btn-group" },
                        [
                          _c(
                            "button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: _vm.trans("task.view_task_note"),
                                  expression: "trans('task.view_task_note')"
                                }
                              ],
                              staticClass: "btn btn-success btn-sm",
                              attrs: {
                                "data-toggle": "modal",
                                "data-target": ".task-note-detail"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showNote(task_note)
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fas fa-arrow-circle-right"
                              })
                            ]
                          ),
                          _vm._v(" "),
                          task_note.user_id === _vm.getAuthUser("id")
                            ? [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.trans("task.edit_task_note"),
                                        expression:
                                          "trans('task.edit_task_note')"
                                      }
                                    ],
                                    staticClass: "btn btn-info btn-sm",
                                    attrs: {
                                      "data-toggle": "modal",
                                      "data-target": ".task-note-form"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.editNote(task_note)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-pencil-alt"
                                    })
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "confirm",
                                        rawName: "v-confirm",
                                        value: {
                                          ok: _vm.ConfirmDeleteNote(task_note)
                                        },
                                        expression:
                                          "{ok: ConfirmDeleteNote(task_note)}"
                                      },
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.trans(
                                          "task.delete_task_note"
                                        ),
                                        expression:
                                          "trans('task.delete_task_note')"
                                      }
                                    ],
                                    key: task_note.id,
                                    staticClass: "btn btn-danger btn-sm"
                                  },
                                  [_c("i", { staticClass: "fas fa-trash" })]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("pagination-record", {
        attrs: {
          "page-length": _vm.filterNoteForm.page_length,
          records: _vm.task_notes
        },
        on: {
          "update:pageLength": function($event) {
            return _vm.$set(_vm.filterNoteForm, "page_length", $event)
          },
          "update:page-length": function($event) {
            return _vm.$set(_vm.filterNoteForm, "page_length", $event)
          },
          updateRecords: _vm.getNotes
        },
        nativeOn: {
          change: function($event) {
            return _vm.getNotes($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "alert alert-info" }, [
      _c("i", { staticClass: "fas fa-exclamation-triangle" }),
      _vm._v(
        "Notes can be public or private. Public note will be available to all the users who can access the abstract. Edit & Delete option is restricted to the user who has created the note."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }