var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.proceed($event)
        },
        keydown: function($event) {
          return _vm.taskForm.errors.clear($event.target.name)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "form-group col-12 col-md-12" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                name: "occupation",
                options: ["Student", "PhD student", "Resident", "Scientist"],
                placeholder: "Primary Author Occupation"
              },
              on: { select: _vm.onTaskOccupationSelect },
              model: {
                value: _vm.taskForm.occupation,
                callback: function($$v) {
                  _vm.$set(_vm.taskForm, "occupation", $$v)
                },
                expression: "taskForm.occupation"
              }
            }),
            _vm._v(" "),
            _c("show-error", {
              attrs: { "form-name": _vm.taskForm, "prop-name": "occupation" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.taskForm.occupation === "Student" ||
      _vm.taskForm.occupation === "Resident"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "form-group col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Which presentation format would you prefer?")
                ]),
                _vm._v(" "),
                _c("v-select", {
                  attrs: {
                    name: "format",
                    options: ["Oral/Poster", "Case report"],
                    placeholder: "Presentation format"
                  },
                  on: { select: _vm.onTaskFormatSelect },
                  model: {
                    value: _vm.taskForm.format,
                    callback: function($$v) {
                      _vm.$set(_vm.taskForm, "format", $$v)
                    },
                    expression: "taskForm.format"
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "format" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      (_vm.taskForm.format === "Oral/Poster" &&
        _vm.taskForm.occupation === "Student") ||
      _vm.taskForm.occupation === "Resident"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-md-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [
                    _vm._v(
                      _vm._s(_vm.trans("task.task_category")) + "\n          "
                    ),
                    _c("span", { staticClass: "badge badge-warning" }, [
                      _vm._v(
                        "The Organisers reserve the right to change your session depending\n            on your abstract content"
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("v-select", {
                    attrs: {
                      label: "name",
                      name: "task_category_id",
                      id: "task_category_id",
                      options: _vm.task_categories,
                      placeholder: _vm.trans("task.select_task_category")
                    },
                    on: {
                      select: _vm.onTaskCategorySelect,
                      close: function($event) {
                        return _vm.taskForm.errors.clear("task_category_id")
                      },
                      remove: function($event) {
                        _vm.taskForm.task_category_id = ""
                      }
                    },
                    model: {
                      value: _vm.selected_task_category,
                      callback: function($$v) {
                        _vm.selected_task_category = $$v
                      },
                      expression: "selected_task_category"
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.taskForm,
                      "prop-name": "task_category_id"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-12" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Add new author",
                  expression: "'Add new author'"
                }
              ],
              staticClass:
                "btn btn-info waves-effect waves-light pull-right btn-md",
              attrs: { type: "button" },
              on: { click: _vm.addExperience }
            },
            [_c("i", { staticClass: "fas fa-plus" })]
          ),
          _vm._v(" "),
          _vm._m(1)
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.taskForm.authors, function(author, index) {
        return _c("div", { key: index, staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-12" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v("Author " + _vm._s(index + 1))
            ]),
            _vm._v(" "),
            index + 1 === 1
              ? _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "btn btn-success waves-effect waves-light pull-right btn-sm",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n        Primary author\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            index + 1 >= 2
              ? _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "confirm",
                        rawName: "v-confirm",
                        value: { ok: _vm.confirmRemoveAuthor(author) },
                        expression: "{ ok: confirmRemoveAuthor(author) }"
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Remove author",
                        expression: "'Remove author'"
                      }
                    ],
                    key: index,
                    staticClass:
                      "btn btn-danger waves-effect waves-light pull-right btn-sm",
                    attrs: { type: "button" }
                  },
                  [_c("i", { staticClass: "fas fa-trash" })]
                )
              : _vm._e(),
            _vm._v(" "),
            index + 1 !== 1 && index === _vm.taskForm.authors.length - 1
              ? _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "btn btn-warning waves-effect waves-light pull-right btn-sm",
                    attrs: { type: "button" }
                  },
                  [_vm._v("\n        Scientific Supervisor\n      ")]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col-12 col-md-3" },
            [
              _c("label", { attrs: { for: "" } }, [
                _vm._v("Scientific degree")
              ]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  name: "authors[" + index + "][scientific_degree]",
                  options: [
                    "Student",
                    "Associates degree",
                    "Bachelors degree",
                    "Masters degree",
                    "Doctoral degree"
                  ],
                  placeholder: "Author Scientific degree"
                },
                model: {
                  value: author.scientific_degree,
                  callback: function($$v) {
                    _vm.$set(author, "scientific_degree", $$v)
                  },
                  expression: "author.scientific_degree"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.taskForm,
                  "prop-name": "author.scientific_degree_" + index
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col-12 col-md-3" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Occupation")]),
              _vm._v(" "),
              _c("v-select", {
                attrs: {
                  name: "authors[" + index + "][occupation]",
                  options: ["Student", "Resident", "PhD student", "Scientist"],
                  placeholder: "Author Occupation"
                },
                model: {
                  value: author.occupation,
                  callback: function($$v) {
                    _vm.$set(author, "occupation", $$v)
                  },
                  expression: "author.occupation"
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: {
                  "form-name": _vm.taskForm,
                  "prop-name": "`author.occupation_${index}`"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col-12 col-md-3" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("First name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: author.firstname,
                    expression: "author.firstname"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "authors[" + index + "][firstname]",
                  placeholder: "First name"
                },
                domProps: { value: author.firstname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(author, "firstname", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.taskForm, "prop-name": "firstname" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col-12 col-md-3" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Last name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: author.lastname,
                    expression: "author.lastname"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "authors[" + index + "][lastname]",
                  placeholder: "Last name"
                },
                domProps: { value: author.lastname },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(author, "lastname", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.taskForm, "prop-name": "lastname" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col-12 col-md-5" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Email")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: author.email,
                    expression: "author.email"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "email",
                  value: "",
                  name: "authors[" + index + "][email]",
                  placeholder: "Author email"
                },
                domProps: { value: author.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(author, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.taskForm, "prop-name": "email" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group col-12 col-md-7" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("Affiliation")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: author.affiliation,
                    expression: "author.affiliation"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  value: "",
                  name: "authors[" + index + "][affiliation]",
                  placeholder: "Affiliation"
                },
                domProps: { value: author.affiliation },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(author, "affiliation", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(2, true),
              _vm._v(" "),
              _c("show-error", {
                attrs: { "form-name": _vm.taskForm, "prop-name": "affiliation" }
              })
            ],
            1
          )
        ])
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster" ||
      _vm.taskForm.format === "Case report"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Title")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "title",
                    model: _vm.taskForm.title,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "title", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("title")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "title" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster" ||
      _vm.taskForm.format === "Case report"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Introduction")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "introduction",
                    model: _vm.taskForm.introduction,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "introduction", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("introduction")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.taskForm,
                    "prop-name": "introduction"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Case report"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [
                  _vm._v("Case presentation")
                ]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "aim",
                    model: _vm.taskForm.aim,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "aim", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("aim")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "aim" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Aim")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "aim",
                    model: _vm.taskForm.aim,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "aim", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("aim")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "aim" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Case report"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Discussion")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "methods",
                    model: _vm.taskForm.methods,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "methods", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("methods")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "methods" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Methods")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "methods",
                    model: _vm.taskForm.methods,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "methods", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("methods")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "methods" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Results")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "results",
                    model: _vm.taskForm.results,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "results", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("results")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: { "form-name": _vm.taskForm, "prop-name": "results" }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster" ||
      _vm.taskForm.format === "Case report"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("Conclusions")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "conclusions",
                    model: _vm.taskForm.conclusions,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "conclusions", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("conclusions")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.taskForm,
                    "prop-name": "conclusions"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster" ||
      _vm.taskForm.format === "Case report"
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-md-12" },
              [
                _c("label", { attrs: { for: "" } }, [_vm._v("References")]),
                _vm._v(" "),
                _c("html-editor", {
                  attrs: {
                    name: "references",
                    model: _vm.taskForm.references,
                    isUpdate: _vm.uuid ? true : false
                  },
                  on: {
                    "update:model": function($event) {
                      return _vm.$set(_vm.taskForm, "references", $event)
                    },
                    clearErrors: function($event) {
                      return _vm.taskForm.errors.clear("references")
                    }
                  }
                }),
                _vm._v(" "),
                _c("show-error", {
                  attrs: {
                    "form-name": _vm.taskForm,
                    "prop-name": "references"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster" &&
      _vm.ToText(_vm.taskForm.conclusions).length +
        _vm.ToText(_vm.taskForm.results).length +
        _vm.ToText(_vm.taskForm.methods).length +
        _vm.ToText(_vm.taskForm.aim).length +
        _vm.ToText(_vm.taskForm.introduction).length >
        3000
        ? _c("div", { staticClass: "col-12 col-md-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "span",
                {
                  staticClass: "badge badge-danger",
                  staticStyle: { "font-size": "18px" }
                },
                [
                  _vm._v(
                    "\n        You have used\n        " +
                      _vm._s(
                        _vm.ToText(_vm.taskForm.conclusions).length +
                          _vm.ToText(_vm.taskForm.results).length +
                          _vm.ToText(_vm.taskForm.methods).length +
                          _vm.ToText(_vm.taskForm.aim).length +
                          _vm.ToText(_vm.taskForm.introduction).length
                      ) +
                      "\n        of 3000 characters"
                  ),
                  _c("br")
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Case report" &&
      _vm.ToText(_vm.taskForm.conclusions).length +
        _vm.ToText(_vm.taskForm.results).length +
        _vm.ToText(_vm.taskForm.methods).length +
        _vm.ToText(_vm.taskForm.aim).length +
        _vm.ToText(_vm.taskForm.introduction).length >
        1500
        ? _c("div", { staticClass: "col-12 col-md-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "span",
                {
                  staticClass: "badge badge-danger",
                  staticStyle: { "font-size": "18px" }
                },
                [
                  _vm._v(
                    "\n        You have used\n        " +
                      _vm._s(
                        _vm.ToText(_vm.taskForm.conclusions).length +
                          _vm.ToText(_vm.taskForm.results).length +
                          _vm.ToText(_vm.taskForm.methods).length +
                          _vm.ToText(_vm.taskForm.aim).length +
                          _vm.ToText(_vm.taskForm.introduction).length
                      ) +
                      "\n        of 1500 characters"
                  ),
                  _c("br")
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Oral/Poster" &&
      _vm.ToText(_vm.taskForm.conclusions).length +
        _vm.ToText(_vm.taskForm.results).length +
        _vm.ToText(_vm.taskForm.methods).length +
        _vm.ToText(_vm.taskForm.aim).length +
        _vm.ToText(_vm.taskForm.introduction).length <=
        3000
        ? _c("div", { staticClass: "col-12 col-md-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "span",
                {
                  staticClass: "badge badge-success",
                  staticStyle: { "font-size": "18px" }
                },
                [
                  _vm._v(
                    "\n        You have used\n        " +
                      _vm._s(
                        _vm.ToText(_vm.taskForm.conclusions).length +
                          _vm.ToText(_vm.taskForm.results).length +
                          _vm.ToText(_vm.taskForm.methods).length +
                          _vm.ToText(_vm.taskForm.aim).length +
                          _vm.ToText(_vm.taskForm.introduction).length
                      ) +
                      "\n        of 3000 characters"
                  ),
                  _c("br")
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.taskForm.format === "Case report" &&
      _vm.ToText(_vm.taskForm.conclusions).length +
        _vm.ToText(_vm.taskForm.results).length +
        _vm.ToText(_vm.taskForm.methods).length +
        _vm.ToText(_vm.taskForm.aim).length +
        _vm.ToText(_vm.taskForm.introduction).length <=
        1500
        ? _c("div", { staticClass: "col-12 col-md-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "span",
                {
                  staticClass: "badge badge-success",
                  staticStyle: { "font-size": "18px" }
                },
                [
                  _vm._v(
                    "\n        You have used\n        " +
                      _vm._s(
                        _vm.ToText(_vm.taskForm.conclusions).length +
                          _vm.ToText(_vm.taskForm.results).length +
                          _vm.ToText(_vm.taskForm.methods).length +
                          _vm.ToText(_vm.taskForm.aim).length +
                          _vm.ToText(_vm.taskForm.introduction).length
                      ) +
                      "\n        of 1500 characters"
                  ),
                  _c("br")
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(
                "Permission issued by the biomedical researches ethical\n          committee"
              )
            ]),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskForm.bioethics,
                  expression: "taskForm.bioethics"
                }
              ],
              attrs: { type: "radio", id: "html", value: "yes" },
              domProps: { checked: _vm._q(_vm.taskForm.bioethics, "yes") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.taskForm, "bioethics", "yes")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "html" } }, [
              _vm._v("Yes (state the approval number and the committee name)")
            ]),
            _c("br"),
            _vm._v(" "),
            _vm.taskForm.bioethics === "yes"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.taskForm.bioethics_comment,
                      expression: "taskForm.bioethics_comment"
                    }
                  ],
                  staticClass: "form-control",
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: {
                    type: "text",
                    value: "",
                    placeholder:
                      "State the approval number and the committee name",
                    required: ""
                  },
                  domProps: { value: _vm.taskForm.bioethics_comment },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.taskForm,
                        "bioethics_comment",
                        $event.target.value
                      )
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.taskForm.bioethics,
                  expression: "taskForm.bioethics"
                }
              ],
              attrs: { type: "radio", id: "css", value: "no" },
              domProps: { checked: _vm._q(_vm.taskForm.bioethics, "no") },
              on: {
                change: function($event) {
                  return _vm.$set(_vm.taskForm, "bioethics", "no")
                }
              }
            }),
            _vm._v(" "),
            _c("label", { attrs: { for: "css" } }, [
              _vm._v(
                "No (provide an explanation as to why the permission was not\n          issued)"
              )
            ]),
            _c("br"),
            _vm._v(" "),
            _vm.taskForm.bioethics === "no"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.taskForm.bioethics_comment,
                      expression: "taskForm.bioethics_comment"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    value: "",
                    placeholder:
                      "Provide an explanation as to why the permission was not issued",
                    required: ""
                  },
                  domProps: { value: _vm.taskForm.bioethics_comment },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.taskForm,
                        "bioethics_comment",
                        $event.target.value
                      )
                    }
                  }
                })
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-12" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _vm._m(3),
              _vm._v(" "),
              _c("file-upload-input", {
                attrs: {
                  "button-text": _vm.trans("general.upload_document"),
                  token: _vm.taskForm.upload_token,
                  module: "task",
                  "clear-file": _vm.clearTaskAttachment,
                  "module-id": _vm.module_id || ""
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._m(4),
      _vm._v(" "),
      (_vm.taskForm.format === "Oral/Poster" &&
        _vm.ToText(_vm.taskForm.conclusions).length +
          _vm.ToText(_vm.taskForm.results).length +
          _vm.ToText(_vm.taskForm.methods).length +
          _vm.ToText(_vm.taskForm.aim).length +
          _vm.ToText(_vm.taskForm.introduction).length <=
          3000) ||
      (_vm.taskForm.format === "Case report" &&
        _vm.ToText(_vm.taskForm.conclusions).length +
          _vm.ToText(_vm.taskForm.results).length +
          _vm.ToText(_vm.taskForm.methods).length +
          _vm.ToText(_vm.taskForm.aim).length +
          _vm.ToText(_vm.taskForm.introduction).length <=
          1500)
        ? _c(
            "button",
            {
              staticClass: "btn btn-info waves-effect waves-light",
              attrs: { type: "submit" }
            },
            [
              _vm.uuid
                ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                : _c("span", [_vm._v("Submit Now")])
            ]
          )
        : _c(
            "button",
            {
              staticClass: "btn btn-info waves-effect waves-light",
              attrs: { disabled: "", type: "submit" }
            },
            [
              _vm.uuid
                ? _c("span", [_vm._v(_vm._s(_vm.trans("general.update")))])
                : _c("span", [_vm._v("Submit Now")])
            ]
          ),
      _vm._v(" "),
      _vm.loader ? _c("div", { staticClass: "loader" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.uuid,
              expression: "uuid"
            }
          ],
          staticClass: "btn btn-danger waves-effect waves-light",
          attrs: { to: "/abstracts" }
        },
        [_vm._v("\n    " + _vm._s(_vm.trans("general.cancel")) + "\n  ")]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _vm._v("Primary author occupation\n        "),
      _c("span", { staticClass: "badge badge-info" }, [
        _vm._v(
          "Residents, PhD students and scientists only can submit abstracts\n          into “Precision medicine” session."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "" } }, [
        _vm._v("Authors "),
        _c("span", { staticClass: "badge badge-warning" }, [_vm._v("(max 10)")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("code", [
      _vm._v(
        "Department, institution / hospital, city, state (if relevant),\n        country."
      ),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "For example: Faculty of Medicine, Medical Academy, Lithuanian\n          University of Health Sciences, Kaunas, Lithuania"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "" } }, [
      _vm._v("Signed Researchers' Approval\n          "),
      _c(
        "a",
        {
          attrs: {
            href:
              "https://submission.ihscforall.eu/uploads/files/template-of-agreement.docx",
            target: "_blank"
          }
        },
        [
          _c("i", {
            staticClass: "fa fa-download",
            attrs: { "aria-hidden": "true" }
          })
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "label label-success" }, [
        _c("i", [_vm._v("Signed researchers' approval")]),
        _vm._v(
          " has to be signed by ALL\n            authors and can be submitted in .pdf, .jpg and .png formats."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-md-12" }, [
        _c(
          "div",
          { staticClass: "alert alert-warning", attrs: { role: "alert" } },
          [
            _vm._v(
              "\n        By submitting I hereby declare, that I do not submit the scientific\n        research of another researcher, I had not illegally used data from\n        other researchers or scientists works, and I am acquainted with these\n        rules. I confirm that all authors had approved this research to be\n        submitted and presented.\n      "
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }