var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "wrapper" } }, [
    _c(
      "div",
      {
        staticClass: "login-register",
        staticStyle: { "background-image": "url(/images/background.jpg)" }
      },
      [
        _c(
          "div",
          { staticClass: "login-box card" },
          [
            _c("div", { staticClass: "card-body" }, [
              _c("img", {
                staticStyle: {
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                  width: "50%",
                  "margin-bottom": "30px"
                },
                attrs: { src: "/uploads/logo/602fc9df5d56d.png" }
              }),
              _vm._v(" "),
              _c(
                "form",
                {
                  staticClass: "form-horizontal form-material",
                  attrs: { id: "registerform" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    },
                    keydown: function($event) {
                      return _vm.registerForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _c("h3", { staticClass: "box-title m-b-20" }, [
                    _vm._v(_vm._s(_vm.trans("auth.sign_up")))
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group " },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.registerForm.first_name,
                                expression: "registerForm.first_name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "first_name",
                              placeholder: _vm.trans("auth.first_name")
                            },
                            domProps: { value: _vm.registerForm.first_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.registerForm,
                                  "first_name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.registerForm,
                              "prop-name": "first_name"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group " },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.registerForm.last_name,
                                expression: "registerForm.last_name"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "last_name",
                              placeholder: _vm.trans("auth.last_name")
                            },
                            domProps: { value: _vm.registerForm.last_name },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.registerForm,
                                  "last_name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.registerForm,
                              "prop-name": "last_name"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "form-group " },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.registerForm.email,
                            expression: "registerForm.email"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          name: "email",
                          placeholder: _vm.trans("auth.email")
                        },
                        domProps: { value: _vm.registerForm.email },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.registerForm,
                              "email",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("show-error", {
                        attrs: {
                          "form-name": _vm.registerForm,
                          "prop-name": "email"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.registerForm.password,
                              expression: "registerForm.password"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "password",
                            name: "password",
                            placeholder: _vm.trans("auth.password")
                          },
                          domProps: { value: _vm.registerForm.password },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.registerForm,
                                "password",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.registerForm,
                            "prop-name": "password"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.registerForm.password_confirmation,
                                expression: "registerForm.password_confirmation"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "password",
                              name: "password_confirmation",
                              placeholder: _vm.trans("auth.confirm_password")
                            },
                            domProps: {
                              value: _vm.registerForm.password_confirmation
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.registerForm,
                                  "password_confirmation",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.registerForm,
                              "prop-name": "password_confirmation"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.getConfig("terms_and_conditions")
                    ? _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "col-md-12" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "checkbox checkbox-success p-t-0 p-l-10"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.registerForm.tnc,
                                      expression: "registerForm.tnc"
                                    }
                                  ],
                                  attrs: {
                                    id: "checkbox-signup",
                                    type: "checkbox",
                                    name: "tnc"
                                  },
                                  domProps: {
                                    checked: Array.isArray(_vm.registerForm.tnc)
                                      ? _vm._i(_vm.registerForm.tnc, null) > -1
                                      : _vm.registerForm.tnc
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a = _vm.registerForm.tnc,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.registerForm,
                                              "tnc",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.registerForm,
                                              "tnc",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(_vm.registerForm, "tnc", $$c)
                                      }
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { attrs: { for: "checkbox-signup" } },
                                  [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          target: "_blank",
                                          href: "/terms-and-conditions"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.trans("auth.accept_tnc"))
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.registerForm,
                                "prop-name": "tnc"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getConfig("recaptcha") &&
                  _vm.getConfig("register_recaptcha")
                    ? _c("div", {
                        staticClass: "g-recaptcha",
                        attrs: {
                          "data-sitekey": _vm.getConfig("recaptcha_key")
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group text-center m-t-20" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("auth.register")))]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group m-b-0" }, [
                    _c("div", { staticClass: "col-sm-12 text-center" }, [
                      _c(
                        "p",
                        [
                          _vm._v(
                            _vm._s(_vm.trans("auth.already_have_account?")) +
                              " "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "text-info m-l-5",
                              attrs: { to: "/login" }
                            },
                            [
                              _c("b", [
                                _vm._v(_vm._s(_vm.trans("auth.sign_in")))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c("guest-footer")
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }