<template>
    <div>
        <div class="row page-titles">
            <div class="col-md-6 col-8 align-self-center">
                <h3 class="text-themecolor m-b-0 m-t-0">Payment Cancelled</h3>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/home">{{trans('general.home')}}</router-link></li>
                    <li class="breadcrumb-item"><router-link to="/abstracts">Abstracts</router-link></li>
                    <li class="breadcrumb-item active">Payment Cancelled</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body align-center">
                        <h2>Payment Cancelled</h2>
                          <router-link to="/abstracts" class="btn btn-danger waves-effect waves-light" v-show="uuid">Back to Abstracts</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        components : { },
        data() {
            return {
                uuid:this.$route.params.uuid
            }
        },
        mounted(){
        }
    }
</script>
