<template>
	<aside class="left-sidebar">
        <div class="scroll-sidebar">
            <nav class="sidebar-nav m-t-20">
                <div class="text-center" v-if="getConfig('maintenance_mode')"><span class="badge badge-danger m-b-10">{{trans('configuration.under_maintenance')}}</span></div>
                <div class="text-center" v-if="!getConfig('mode')"><span class="badge badge-danger m-b-10">{{trans('configuration.test_mode')}}</span></div>
                <ul id="sidebarnav">
                    <li><router-link to="/home" exact><i class="fas fa-home fa-fw"></i> <span class="hide-menu">{{trans('general.home')}}</span></router-link></li>
                    <li v-if="hasPermission('list-department') && getConfig('show_department_menu')"><router-link to="/department" exact><i class="fas fa-university fa-fw"></i> <span class="hide-menu">{{trans('department.department')}}</span></router-link></li>
                    <li v-if="hasPermission('list-designation') && getConfig('show_designation_menu')"><router-link to="/designation" exact><i class="fas fa-sitemap fa-fw"></i> <span class="hide-menu">{{trans('designation.designation')}}</span></router-link></li>
                    <li v-if="hasPermission('list-location') && getConfig('show_location_menu')"><router-link to="/location" exact><i class="fas fa-code-branch fa-fw"></i> <span class="hide-menu">{{trans('location.location')}}</span></router-link></li>
                    <li v-if="hasPermission('list-user') && getConfig('show_user_menu')"><router-link to="/user" exact><i class="fas fa-users fa-fw"></i> <span class="hide-menu">{{trans('user.user')}}</span></router-link></li>
                    <li v-if="hasPermission('list-user') && getConfig('show_announcement_menu')"><router-link to="/announcement" exact><i class="fas fa-bullhorn fa-fw"></i> <span class="hide-menu">{{trans('announcement.announcement')}}</span></router-link></li>
                    <li v-if="hasPermission('list-task') && getConfig('show_task_menu')"><router-link to="/abstracts" exact><i class="fas fa-tasks fa-fw"></i> <span class="hide-menu">Abstracts</span></router-link></li>
                    <li  v-if="hasPermission('list-department') && getConfig('show_department_menu')">
                        <a class="has-arrow" href="#" aria-expanded="false"><i class="fas fa-file fa-fw"></i> <span class="hide-menu">{{trans('general.report')}}</span></a>
                        <ul aria-expanded="false" class="collapse">
                            <li><router-link to="/report/abstracts/summary"><i class="fas fa-angle-double-right"></i> {{trans('task.task_summary')}}</router-link></li>
                        </ul>
                    </li>
                    <li v-if="hasPermission('access-message') && getConfig('show_message_menu')"><router-link to="/message" exact><i class="fas fa-envelope-open fa-fw"></i> <span class="hide-menu">{{trans('message.message')}}</span></router-link></li>
                </ul>
            </nav>
        </div>
        <div class="sidebar-footer">
            <router-link v-if="hasPermission('access-configuration')" to="/configuration" class="link" v-tooltip="trans('configuration.configuration')"><i class="fas fa-cogs"></i></router-link>
            <router-link to="/profile" class="link" v-tooltip="trans('user.profile')"><i class="fas fa-user"></i></router-link>
            <a href="#" class="link" v-tooltip="trans('auth.logout')" @click.prevent="logout"><i class="fas fa-power-off"></i></a>
        </div>
    </aside>
</template>

<script>
    export default {
        mounted() {
        },
        methods : {
            logout(){
                helper.logout().then(() => {
                    this.$store.dispatch('resetAuthUserDetail');
                    this.$router.push('/login');
                })
            },
            getAuthUser(name){
                return helper.getAuthUser(name);
            },
            hasPermission(permission){
                return helper.hasPermission(permission);
            },
            getConfig(config){
                return helper.getConfig(config);
            }
        },
        computed: {
        }
    }
</script>
