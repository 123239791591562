var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row page-titles" }, [
      _c("div", { staticClass: "col-md-6 col-8 align-self-center" }, [
        _c("h3", { staticClass: "text-themecolor m-b-0 m-t-0" }, [
          _vm._v("Abstracts")
        ]),
        _vm._v(" "),
        _c("ol", { staticClass: "breadcrumb" }, [
          _c(
            "li",
            { staticClass: "breadcrumb-item" },
            [
              _c("router-link", { attrs: { to: "/home" } }, [
                _vm._v(_vm._s(_vm.trans("general.home")))
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c("li", { staticClass: "breadcrumb-item active" }, [
            _vm._v("Abstracts")
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _vm.hasPermission("create-task")
            ? _c("transition", { attrs: { name: "fade" } }, [
                _vm.showCreatePanel
                  ? _c("div", { staticClass: "card" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _vm.showCreatePanel
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-info btn-sm pull-right",
                                  on: {
                                    click: function($event) {
                                      _vm.showCreatePanel = !_vm.showCreatePanel
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.trans("general.hide")) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("h4", { staticClass: "card-title" }, [
                            _vm._v(_vm._s(_vm.trans("task.add_new_task")))
                          ]),
                          _vm._v(" "),
                          _c("task-form", { on: { completed: _vm.getTasks } })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.showFilterPanel
              ? _c("div", { staticClass: "card" }, [
                  _c("div", { staticClass: "card-body" }, [
                    _vm.showFilterPanel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-sm pull-right",
                            on: {
                              click: function($event) {
                                _vm.showFilterPanel = !_vm.showFilterPanel
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.trans("general.hide")) +
                                "\n            "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v(_vm._s(_vm.trans("general.filter")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("div", { staticClass: "col-6 col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Abstract number")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterTaskForm.number,
                                  expression: "filterTaskForm.number"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "title" },
                              domProps: { value: _vm.filterTaskForm.number },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterTaskForm,
                                    "number",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Abstract topic")
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.filterTaskForm.title,
                                  expression: "filterTaskForm.title"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { name: "title" },
                              domProps: { value: _vm.filterTaskForm.title },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.filterTaskForm,
                                    "title",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-md-6" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v(_vm._s(_vm.trans("task.task_category")))
                              ]),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  label: "name",
                                  "track-by": "id",
                                  name: "task_category_id",
                                  id: "task_category_id",
                                  options: _vm.task_categories,
                                  placeholder: _vm.trans(
                                    "task.select_task_category"
                                  ),
                                  multiple: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  "hide-selected": true
                                },
                                on: {
                                  select: _vm.onTaskCategorySelect,
                                  remove: _vm.onTaskCategoryRemove
                                },
                                model: {
                                  value: _vm.selected_task_category,
                                  callback: function($$v) {
                                    _vm.selected_task_category = $$v
                                  },
                                  expression: "selected_task_category"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          attrs: {
                            label: "name",
                            "track-by": "id",
                            name: "task_priority_id",
                            id: "task_priority_id",
                            options: _vm.task_priorities,
                            placeholder: _vm.trans("task.select_task_priority"),
                            multiple: true,
                            "close-on-select": false,
                            "clear-on-select": false,
                            "hide-selected": true,
                            hidden: ""
                          },
                          on: {
                            select: _vm.onTaskPrioritySelect,
                            remove: _vm.onTaskPriorityRemove
                          },
                          model: {
                            value: _vm.selected_task_priority,
                            callback: function($$v) {
                              _vm.selected_task_priority = $$v
                            },
                            expression: "selected_task_priority"
                          }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Reviewer")
                              ]),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  label: "name",
                                  "track-by": "id",
                                  name: "user_id",
                                  id: "user_id",
                                  options: _vm.users,
                                  placeholder: _vm.trans("user.select_user"),
                                  multiple: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  "hide-selected": true
                                },
                                on: {
                                  select: _vm.onUserSelect,
                                  remove: _vm.onUserRemove
                                },
                                model: {
                                  value: _vm.selected_user,
                                  callback: function($$v) {
                                    _vm.selected_user = $$v
                                  },
                                  expression: "selected_user"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Primary author")
                              ]),
                              _vm._v(" "),
                              _c("v-select", {
                                attrs: {
                                  label: "name",
                                  "track-by": "id",
                                  name: "user_id",
                                  id: "user_id",
                                  options: _vm.users,
                                  placeholder: _vm.trans("user.select_user"),
                                  multiple: true,
                                  "close-on-select": false,
                                  "clear-on-select": false,
                                  "hide-selected": true
                                },
                                on: {
                                  select: _vm.onUserSelect1,
                                  remove: _vm.onUserRemove1
                                },
                                model: {
                                  value: _vm.selected_user1,
                                  callback: function($$v) {
                                    _vm.selected_user1 = $$v
                                  },
                                  expression: "selected_user1"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-md-6" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("task.status")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterTaskForm.status,
                                    expression: "filterTaskForm.status"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  name: "status",
                                  placeholder: _vm.trans("general.select_one")
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filterTaskForm,
                                      "status",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "" } }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.trans("general.select_one")) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "rejected" } }, [
                                  _vm._v("Rejected")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "pending" } }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.trans("task.pending")) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "approved" } }, [
                                  _vm._v("Accepted")
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "payment" } }, [
                                  _vm._v("Waiting for Payment")
                                ])
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("general.sort_by")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterTaskForm.sort_by,
                                    expression: "filterTaskForm.sort_by"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { name: "sort_by" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filterTaskForm,
                                      "sort_by",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "title" } }, [
                                  _vm._v(_vm._s(_vm.trans("task.title")))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "task_category_id" } },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.trans("task.task_category")
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "task_priority_id" } },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(
                                          _vm.trans("task.task_priority")
                                        ) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "start_date" } },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.trans("task.start_date")) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "due_date" } }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.trans("task.due_date")) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "completed_at" } },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.trans("task.completed_at")) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "created_at" } },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(_vm.trans("task.created_at")) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-6 col-md-3" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("general.order")))
                            ]),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterTaskForm.order,
                                    expression: "filterTaskForm.order"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { name: "order" },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.filterTaskForm,
                                      "order",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              [
                                _c("option", { attrs: { value: "asc" } }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.trans("general.ascending")) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "desc" } }, [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(_vm.trans("general.descending")) +
                                      "\n                    "
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.hasPermission("list-task")
            ? _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _vm.hasPermission("filter-abstracts")
                      ? _c("div", [
                          !_vm.showFilterPanel
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-info btn-sm pull-right",
                                  on: {
                                    click: function($event) {
                                      _vm.showFilterPanel = !_vm.showFilterPanel
                                    }
                                  }
                                },
                                [
                                  _c("i", { staticClass: "fas fa-filter" }),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.trans("general.filter")) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.tasks.total && !_vm.showCreatePanel
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-sm pull-right m-r-5",
                            on: {
                              click: function($event) {
                                _vm.showCreatePanel = !_vm.showCreatePanel
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(" Add new Abstract\n          ")
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h4", { staticClass: "card-title" }, [
                      _vm._v("\n            List of Abstracts\n            "),
                      _vm.filterTaskForm.user_id.length == 1
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.trans("general.of") +
                                  " " +
                                  _vm.getRatingUser
                              )
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.tasks
                      ? _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.trans("general.total_result_found", {
                                  count: _vm.tasks.total
                                })
                              ) +
                              "\n          "
                          )
                        ])
                      : _c("h6", { staticClass: "card-subtitle" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.trans("general.no_result_found")) +
                              "\n          "
                          )
                        ]),
                    _vm._v(" "),
                    _vm.tasks.total
                      ? _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table" }, [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Abstract ID")]),
                                _vm._v(" "),
                                _c("th", [_vm._v("Abstract topic")]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.trans("task.status")))
                                ]),
                                _vm._v(" "),
                                _vm.filterTaskForm.user_id.length == 1
                                  ? _c("th", [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.trans("task.rating")) +
                                          "\n                  "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.hasPermission("list-announcement")
                                  ? _c("th", [
                                      _vm._v(
                                        "\n                    Primary author\n                  "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("th", { staticClass: "table-option" }, [
                                  _vm._v(_vm._s(_vm.trans("general.action")))
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "tbody",
                              _vm._l(_vm.tasks.data, function(task) {
                                return _c("tr", [
                                  _c("td", {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.getTaskNumber(task)
                                      )
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("td", {
                                    domProps: { innerHTML: _vm._s(task.title) }
                                  }),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm.hasPermission("list-announcement")
                                      ? _c(
                                          "span",
                                          _vm._l(
                                            _vm.getTaskStatus(task),
                                            function(status) {
                                              return _c(
                                                "span",
                                                {
                                                  class: [
                                                    "label",
                                                    "label-" + status.color
                                                  ],
                                                  staticStyle: {
                                                    "margin-right": "5px"
                                                  }
                                                },
                                                [_vm._v(_vm._s(status.label))]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermission("filter-abstracts")
                                      ? _c("span", [
                                          task.reviewer1
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label label-success"
                                                },
                                                [_vm._v("Reviewed 1")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          task.reviewer2
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label label-success"
                                                },
                                                [_vm._v("Reviewed 2")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          task.reviewer3
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label label-success"
                                                },
                                                [_vm._v("Reviewed 3")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          task.reviewer4
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label label-success"
                                                },
                                                [_vm._v("Reviewed 4")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          task.reviewer5
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label label-success"
                                                },
                                                [_vm._v("Reviewed 5")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          task.reviewer6
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "label label-success"
                                                },
                                                [_vm._v("Reviewed 6")]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.hasPermission("do-review")
                                      ? _c(
                                          "span",
                                          [
                                            _vm._l(_vm.reviewers, function(
                                              reviewer
                                            ) {
                                              return _c("span", [_vm._v(" 1 ")])
                                            }),
                                            _vm._v(" "),
                                            task.reviewer1 ==
                                            _vm.getAuthUser("id")
                                              ? _c("span", [
                                                  task.reviewer1 ==
                                                  _vm.getAuthUser("id")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-success"
                                                        },
                                                        [_vm._v("Reviewed")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need to review"
                                                          )
                                                        ]
                                                      )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            task.reviewer2 ==
                                            _vm.getAuthUser("id")
                                              ? _c("span", [
                                                  task.reviewer2 ==
                                                  _vm.getAuthUser("id")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-success"
                                                        },
                                                        [_vm._v("Reviewed")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need to review"
                                                          )
                                                        ]
                                                      )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            task.reviewer3 ==
                                            _vm.getAuthUser("id")
                                              ? _c("span", [
                                                  task.reviewer3 ==
                                                  _vm.getAuthUser("id")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-success"
                                                        },
                                                        [_vm._v("Reviewed")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need to review"
                                                          )
                                                        ]
                                                      )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            task.reviewer4 ==
                                            _vm.getAuthUser("id")
                                              ? _c("span", [
                                                  task.reviewer4 ==
                                                  _vm.getAuthUser("id")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-success"
                                                        },
                                                        [_vm._v("Reviewed")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need to review"
                                                          )
                                                        ]
                                                      )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            task.reviewer5 ==
                                            _vm.getAuthUser("id")
                                              ? _c("span", [
                                                  task.reviewer5 ==
                                                  _vm.getAuthUser("id")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-success"
                                                        },
                                                        [_vm._v("Reviewed")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need to review"
                                                          )
                                                        ]
                                                      )
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            task.reviewer6 ==
                                            _vm.getAuthUser("id")
                                              ? _c("span", [
                                                  task.reviewer6 ==
                                                  _vm.getAuthUser("id")
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-success"
                                                        },
                                                        [_vm._v("Reviewed")]
                                                      )
                                                    : _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "label label-warning"
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Need to review"
                                                          )
                                                        ]
                                                      )
                                                ])
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]),
                                  _vm._v(" "),
                                  _vm.filterTaskForm.user_id.length == 1
                                    ? _c("td", {
                                        domProps: {
                                          innerHTML: _vm._s(_vm.getRating(task))
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasPermission("list-announcement")
                                    ? _c("td", [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              task.user_added.profile
                                                .first_name +
                                                " " +
                                                task.user_added.profile
                                                  .last_name
                                            ) +
                                            "\n                    "
                                        ),
                                        _vm.hasPermission("delete-task")
                                          ? _c("p", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    task.user_added.email
                                                  ) +
                                                  "\n                    "
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", { staticClass: "table-option" }, [
                                    _c(
                                      "div",
                                      { staticClass: "btn-group" },
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            directives: [
                                              {
                                                name: "tooltip",
                                                rawName: "v-tooltip",
                                                value: _vm.trans(
                                                  "task.view_task"
                                                ),
                                                expression:
                                                  "trans('task.view_task')"
                                              }
                                            ],
                                            staticClass:
                                              "btn btn-success btn-sm",
                                            attrs: {
                                              to: "/abstracts/" + task.uuid
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-arrow-circle-right"
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        task.sign_off_status === "approved" &&
                                        _vm.hasPermission("delete-task")
                                          ? [
                                              !task.is_archived
                                                ? _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "task.move_to_archive"
                                                          ),
                                                          expression:
                                                            "trans('task.move_to_archive')"
                                                        },
                                                        {
                                                          name: "confirm",
                                                          rawName: "v-confirm",
                                                          value: {
                                                            ok: _vm.confirmToggleArchive(
                                                              task
                                                            )
                                                          },
                                                          expression:
                                                            "{ ok: confirmToggleArchive(task) }"
                                                        }
                                                      ],
                                                      key: "archive_" + task.id,
                                                      staticClass:
                                                        "btn btn-warning btn-sm"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-archive"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              task.is_archived
                                                ? _c(
                                                    "button",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "tooltip",
                                                          rawName: "v-tooltip",
                                                          value: _vm.trans(
                                                            "task.remove_from_archive"
                                                          ),
                                                          expression:
                                                            "trans('task.remove_from_archive')"
                                                        },
                                                        {
                                                          name: "confirm",
                                                          rawName: "v-confirm",
                                                          value: {
                                                            ok: _vm.confirmToggleArchive(
                                                              task
                                                            )
                                                          },
                                                          expression:
                                                            "{ ok: confirmToggleArchive(task) }"
                                                        }
                                                      ],
                                                      key:
                                                        "unarchive_" + task.id,
                                                      staticClass:
                                                        "btn btn-warning btn-sm"
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-archive"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermission("edit-task")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: "Edit Abstract",
                                                    expression:
                                                      "'Edit Abstract'"
                                                  }
                                                ],
                                                staticClass:
                                                  "btn btn-info btn-sm",
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.editTask(task)
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-pencil-alt"
                                                })
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasPermission("delete-task")
                                          ? _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "confirm",
                                                    rawName: "v-confirm",
                                                    value: {
                                                      ok: _vm.confirmDelete(
                                                        task
                                                      )
                                                    },
                                                    expression:
                                                      "{ ok: confirmDelete(task) }"
                                                  },
                                                  {
                                                    name: "tooltip",
                                                    rawName: "v-tooltip",
                                                    value: _vm.trans(
                                                      "task.delete_task"
                                                    ),
                                                    expression:
                                                      "trans('task.delete_task')"
                                                  }
                                                ],
                                                key: task.id,
                                                staticClass:
                                                  "btn btn-danger btn-sm"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fas fa-trash"
                                                })
                                              ]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.tasks.total
                      ? _c(
                          "module-info",
                          {
                            attrs: {
                              module: "task",
                              title: "module_info_title",
                              description: "module_info_description",
                              icon: "tasks"
                            }
                          },
                          [
                            _c("div", { attrs: { slot: "btn" }, slot: "btn" }, [
                              !_vm.showCreatePanel
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info btn-md",
                                      on: {
                                        click: function($event) {
                                          _vm.showCreatePanel = !_vm.showCreatePanel
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "fas fa-plus" }),
                                      _vm._v(
                                        " Submit Abstract now!\n              "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterTaskForm.page_length,
                        records: _vm.tasks
                      },
                      on: {
                        "update:pageLength": function($event) {
                          return _vm.$set(
                            _vm.filterTaskForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function($event) {
                          return _vm.$set(
                            _vm.filterTaskForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getTasks
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }