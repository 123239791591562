<template>
  <div>
    <div class="row page-titles">
      <div class="col-md-6 col-8 align-self-center">
        <h3 class="text-themecolor m-b-0 m-t-0">{{ trans("general.home") }}</h3>
        <ol class="breadcrumb">
          <li class="breadcrumb-item active">{{ trans("general.home") }}</li>
        </ol>
      </div>
    </div>
    <div class="row" v-if="hasPermission('show-counts')">
      <div class="col-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Total Abstracts</h4>
            <div class="text-right">
              <h2 class="font-light m-b-0">
                <i class="fas fa-tasks fa-lg pull-right text-info"></i>
                <span class="pull-left">{{ task_stats.total }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Pending Abstratcs</h4>
            <div class="text-right">
              <h2 class="font-light m-b-0">
                <i class="fas fa-spinner fa-lg pull-right text-danger"></i>
                <span class="pull-left">{{ task_stats.pending }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Waiting for Payment</h4>
            <div class="text-right">
              <h2 class="font-light m-b-0">
                <i class="fas fa-spinner fa-lg pull-right text-danger"></i>
                <span class="pull-left">{{ task_stats.payment }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-3">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Approved Abstracts</h4>
            <div class="text-right">
              <h2 class="font-light m-b-0">
                <i
                  class="fas fa-battery-full fa-lg pull-right text-success"
                ></i>
                <span class="pull-left">{{ task_stats.completed }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="!hasPermission('do-review')">
      <div class="col-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="message-scroll">
              <h4 class="card-title">
                CALL FOR ABSTRACTS | OPEN UNTIL 14th of January 2024, 23:59 EET
              </h4>
              <div class="comment-widgets">
                <h1 style="text-align: center;">
                  <strong
                    >Welcome to the Abstract Submission, Peer Review System for
                    IHSC 2024</strong
                  >
                </h1>
                <p style="text-align: center;">
                  <span style="font-size: 16px;"
                    >Please make sure you have read the
                    <a
                      target="_blank"
                      href="https://ihsc.eu/index.php/participation/"
                      rel="noopener"
                      >guidelines and responsibilities</a
                    >
                    associated with submitting an abstract.&nbsp;&nbsp;</span
                  >
                </p>
                <p style="text-align: center;">
                  <span style="font-size: 16px;"
                    >As the submitter,
                    <span style="text-decoration: underline;"
                      >you will be designated the Primary author</span
                    >
                    and will receive all communications (confirmations,
                    notifications, reviews, etc).&nbsp; It is your
                    responsibility to forward any relevant communications to
                    your co-authors and fulfill the tasks by the appointed
                    deadlines.</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12" v-if="getConfig('announcement')">
        <div class="card">
          <div class="card-body">
            <div class="message-scroll">
              <h4 class="card-title">Announcements</h4>
              <div class="comment-widgets">
                <div
                  class="d-flex flex-row comment-row"
                  v-for="announcement in announcements"
                >
                  <div v-html="announcement.description"></div>
                </div>
              </div>
              <h6 class="card-subtitle" v-if="!announcements.length">
                {{ trans("general.no_result_found") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import announcementDetail from "../announcement/detail";
import doughnutGraph from "../graph/doughnut-graph";
import taskList from "../task/task-list";

export default {
  components: { announcementDetail, doughnutGraph, taskList },
  data() {
    return {
      task_stats: {},
      activity_logs: {},
      pending_todos: [],
      completed_todos: [],
      announcements: [],
      announcement_id: "",
      top_charts: [],
      graph: {
        task_category: {
          labels: []
        },
        task_priority: {
          labels: []
        },
        task_status: {
          labels: []
        }
      },
      tabs: {
        showStarredTab: true,
        showPendingTab: false,
        showOverdueTab: false,
        showOwnedTab: false,
        showUnassignedTab: false
      }
    };
  },
  mounted() {
    axios
      .get("/api/dashboard")
      .then(response => response.data)
      .then(response => {
        this.task_stats = response.task_stats;
        this.activity_logs = response.activity_logs;
        this.announcements = response.announcements;
      })
      .catch(error => {
        helper.showDataErrorMsg(error);
      });

    if (this.getConfig("todo")) this.getRecentTodo();

    this.getUserRatingTopChart();

    this.getGraphData();
  },
  methods: {
    showHideTabs(activeTab) {
      for (let tab in this.tabs) if (tab !== activeTab) this.tabs[tab] = false;
      this.tabs[activeTab] = true;
    },
    getRecentTodo() {
      axios
        .post("/api/todo/recent")
        .then(response => response.data)
        .then(response => {
          this.pending_todos = response.pending_todos;
          this.completed_todos = response.completed_todos;
        })
        .catch(error => {
          helper.showDataErrorMsg(error);
        });
    },
    toggleTodo(todo) {
      axios
        .post("/api/todo/" + todo.id + "/status")
        .then(response => response.data)
        .then(response => {
          this.getRecentTodo();
        })
        .catch(error => {
          helper.showDataErrorMsg();
        });
    },
    getGraphData() {
      axios
        .post("/api/task/graph")
        .then(response => response.data)
        .then(response => {
          this.graph = response;
        })
        .catch(error => {
          helper.showDataErrorMsg();
        });
    },
    hasPermission(permission) {
      return helper.hasPermission(permission);
    },
    getStatus(todo) {
      return todo.status
        ? '<span class="label label-success">' + i18n.todo.complete + "</span>"
        : '<span class="label label-danger">' +
            i18n.todo.incomplete +
            "</span>";
    },
    hasRole(role) {
      return helper.hasRole(role);
    },
    hasAdminRole() {
      return helper.hasAdminRole();
    },
    getAvatar(user) {
      return helper.getAvatar(user);
    },
    getConfig(name) {
      return helper.getConfig(name);
    },
    getUserRatingTopChart() {
      axios
        .post("/api/task/rating/chart")
        .then(response => response.data)
        .then(response => (this.top_charts = response))
        .catch(error => {
          helper.showDataErrorMsg();
        });
    },
    generateRatingStar(rating) {
      return helper.generateRatingStar(rating);
    }
  },
  computed: {},
  filters: {
    momentDateTime(date) {
      return helper.formatDateTime(date);
    },
    moment(date) {
      return helper.formatDate(date);
    }
  }
};
</script>
