var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", { staticClass: "col-12 col-lg-4 col-md-4" }, [
        _c("div", { staticClass: "card" }, [
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c("center", { staticClass: "m-t-30" }, [
                _c("img", {
                  staticClass: "img-circle",
                  attrs: { src: _vm.getAvatar(_vm.user), width: "150" }
                }),
                _vm._v(" "),
                _c("h4", { staticClass: "card-title m-t-10" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.user.profile.first_name +
                          " " +
                          _vm.user.profile.last_name
                      ) +
                      "\n          "
                  ),
                  _vm.user.profile.gender === "male"
                    ? _c("span", [
                        _c("i", { staticClass: "fas fa-male fa-2x" })
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.user.profile.gender === "female"
                    ? _c("span", [
                        _c("i", { staticClass: "fas fa-female fa-2x" })
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.getUserStatus(_vm.user), function(status) {
                    return _c(
                      "span",
                      { class: ["label", "label-" + status.color, "m-r-5"] },
                      [_vm._v(_vm._s(status.label))]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.user.id != _vm.getAuthUser("id")
                  ? _c("div", { staticClass: "row m-t-10" }, [
                      _vm.user.status == "activated"
                        ? _c("div", { staticClass: "col-12" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-danger",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus("banned")
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-ban" }),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.trans("user.user_action_ban")) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-warning",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.sendWelcomeEmail()
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-mail" }),
                                _vm._v(
                                  " Send Welcome Email to Reviewer\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.status == "disapproved"
                        ? _c("div", { staticClass: "col-12" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-success",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus("activated")
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-check" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.trans("user.user_action_approve")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.status == "pending_activation" ||
                      _vm.user.status == "pending_approval"
                        ? _c("div", { staticClass: "col-6" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-success",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus("activated")
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-user-plus" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.trans("user.user_action_approve")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.status == "pending_activation" ||
                      _vm.user.status == "pending_approval"
                        ? _c("div", { staticClass: "col-6" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-danger",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus("disapproved")
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-user-times" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.trans("user.user_action_disapprove")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.user.status == "banned"
                        ? _c("div", { staticClass: "col-12" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-block btn-success",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.updateStatus("activated")
                                  }
                                }
                              },
                              [
                                _c("i", { staticClass: "fas fa-check" }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.trans("user.user_action_activate")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ],
            1
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.trans("user.email")))
            ]),
            _vm._v(" "),
            _c("h6", [_vm._v(_vm._s(_vm.user.email))]),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(_vm._s(_vm.trans("user.created_at")))
            ]),
            _vm._v(" "),
            _c("h6", [_vm._v(_vm._s(_vm._f("moment")(_vm.user.created_at)))]),
            _vm._v(" "),
            _vm.user.profile.phone
              ? _c("small", { staticClass: "text-muted p-t-30 db" }, [
                  _vm._v(_vm._s(_vm.trans("user.phone")))
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.user.profile.phone
              ? _c("h6", [_vm._v(_vm._s(_vm.user.profile.phone))])
              : _vm._e(),
            _vm._v(" "),
            _c("small", { staticClass: "text-muted p-t-30 db" }, [
              _vm._v(_vm._s(_vm.trans("user.social_profile")))
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm.user.profile.facebook_profile
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-circle btn-secondary",
                    attrs: { href: _vm.user.profile.facebook_profile }
                  },
                  [_c("i", { staticClass: "fab fa-facebook" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.user.profile.twitter_profile
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-circle btn-secondary",
                    attrs: { href: _vm.user.profile.twitter_profile }
                  },
                  [_c("i", { staticClass: "fab fa-twitter" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.user.profile.linkedin_profile
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-circle btn-secondary",
                    attrs: { href: _vm.user.profile.linkedin_profile }
                  },
                  [_c("i", { staticClass: "fab fa-linkedin" })]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.user.profile.google_plus_profile
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-circle btn-secondary",
                    attrs: { href: _vm.user.profile.google_plus_profile }
                  },
                  [_c("i", { staticClass: "fab fa-google" })]
                )
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }